import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { MessagesMenu } from '../messagesMenu/messagesMenu';
import { formatDateIT } from '../../utils/date';
import { MessageType, MessagesModel } from '../../models/messagesModel';
import './messagesListRow.css';
import { MessagesService } from '../../services/messagesService';
import {
  getMessagesList,
  getMessagesReports,
  getUnreadCounters,
  setMessagesListPage,
  setMessagesListStatusFilter,
} from '../../redux/slices/messagesListSlice';
import { useAppDispatch } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { plainString } from '../../utils/string';
import uri from '../../routers/quickfiscoUri.json';
import lang from './messagesListRow.json';

interface Props {
  message: MessagesModel;
}

export function MessagesListRow(props: Props) {
  const { message } = props;

  const [onMouseOver, setOnMouseOver] = useState<boolean>(false);
  const [unreadStatus, setUnreadStatus] = useState<PromiseStatuses>('idle');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const menuIsVisible = useComponentVisible(false);
  let messageType: string;
  switch (message.type) {
    case MessageType.FULLFILLMENTS:
      messageType = lang.fulfillments;
      break;
    case MessageType.HEY:
      messageType = lang.hey;
      break;
    case MessageType.NEWS:
      messageType = lang.news;
      break;
    case MessageType.WARNING:
      messageType = lang.warning;
      break;
    default:
      return <div>Tipo di messaggio non valido.</div>;
  }

  const goToMessage = async () => {
    navigate(uri.Messages + '/' + message.id);
  };

  const markAsRead = async () => {
    menuIsVisible.setIsVisible(false);

    if (message.id) {
      const service = new MessagesService();

      setUnreadStatus('loading');
      service
        .markAsRead(message.id)
        .then(() => {
          menuIsVisible.setIsVisible(false);
          setUnreadStatus('idle');
          dispatch(getMessagesReports());
          dispatch(getUnreadCounters());
          dispatch(setMessagesListPage(1));
          dispatch(
            getMessagesList({
              page: 1,
            })
          );
          dispatch(setMessagesListStatusFilter(undefined));
        })
        .catch((err) => {
          setUnreadStatus('failed');
          console.error(err);
        });
    }
  };

  return (
    <div
      className={`row no-gutters row-messages-container${
        message.unread ? '-unread' : ''
      }`}
    >
      <div className={'row no-gutters'}>
        <div className={'col-12 no-gutters'}>
          <div className={'messagesListRow-line'} />
        </div>
      </div>
      <div className={'row no-gutters messagesListRow-row align-items-center'}>
        <div
          className={'col no-gutters pb-3 pt-3'}
          onClick={() => goToMessage()}
        >
          <div className={'row no-gutters align-items-center'}>
            <div className="col messagesListRow-message-type">
              <div
                className={`messagesListRow-type${
                  message.unread ? '-unread' : ''
                }-${message.type.toLowerCase()}`}
              >
                {messageType}
              </div>
            </div>
            <div
              className={`col messagesListRow-message${
                message.unread ? '-unread' : ''
              } d-flex align-items-center`}
            >
               <div>
                {message.important && <QuickfiscoIcon name={'warning.svg'} />}
              </div>
              <div>
                <div>
                  <span>
                    {message.objectMessage && message.objectMessage.length > 60
                      ? `${message.objectMessage.substring(0, 60)}...`
                      : message.objectMessage}
                  </span>
                </div>
                <div>
                  {message.textMessage && message.textMessage.length > 50
                    ? `${plainString(message.textMessage).substring(0, 50)}...`
                    : plainString(message.textMessage)}
                </div>
              </div>
            </div>
            <div className={'col messagesListRow-gray messagesListRow-attachment'}>
              {message.documents?.map((doc) => (
                <div key={doc.object.id}>
                  <QuickfiscoIcon name={'attachment.svg'} /> {doc.object.name}
                </div>
              ))}
            </div>
            {message.sentDate && (
              <div className={'col messagesListRow-gray messagesListRow-date'}>
                {formatDateIT(message.sentDate)}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            'col-auto position-relative d-flex align-items-start justify-content-end'
          }
        >
          {menuIsVisible.isVisible ? (
            <div>
              <QuickfiscoIcon name={'menu-active.svg'} />
            </div>
          ) : (
            <div
              onMouseOver={() => setOnMouseOver(false)}
              onMouseOut={() => setOnMouseOver(false)}
              onClick={() => {
                menuIsVisible.setIsVisible(true);
                setOnMouseOver(false);
              }}
            >
              <QuickfiscoIcon
                name={onMouseOver ? 'menu-hover.svg' : 'menu.svg'}
              />
            </div>
          )}
          <div className={'messagesListRow-menu'} ref={menuIsVisible.ref}>
            <MessagesMenu
              message={message}
              markAsRead={markAsRead}
              unreadStatus={unreadStatus}
              setUnreadStatus={setUnreadStatus}
              componentVisible={menuIsVisible}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
