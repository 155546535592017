import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ObjectService } from '../../services/objectService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import lang from './documentsPreviewModal.json';
import './documentsPreviewModal.css';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  docId?: string;
}

export function DocumentsPreviewModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { docId } = props;

  return (
    <QuickfiscoModal
      modalClassName={'documents-preview-modal-component'}
      isOpen={open}
      hide={() => setOpen(false)}
    >
      <div className={'row'}>
        <div
          className={
            'col-10 offset-1 text-center documents-preview-modal-title'
          }
        >
          {lang.modalTitle}
        </div>
      </div>
      <div className={'row my-3'}>
        <div className={'col-10 offset-1 text-center border-gray'}>
          <div className="documents-preview-modal-render-file d-flex justify-content-center align-items-center my-4">
            {docId ? (
              <Document
                file={getFileUrl(docId)}
                loading={() => {
                  return (
                    <div
                      className={
                        'w-100 h-100 d-flex justify-content-center align-items-center'
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  );
                }}
                error={lang.previewPdfError}
              >
                <Page pageNumber={1} />
              </Document>
            ) : (
              <div>{lang.previewPdfError}</div>
            )}
          </div>
        </div>
      </div>
      <div className={'row mb-5'}>
        <div className={'col-4 offset-4 text-left'}>
          <QuickfiscoButton
            id={'documents-modal-download-pdf'}
            label={lang.close}
            type={'primary'}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function getFileUrl(documentId: string): string {
  const service = new ObjectService();
  return service.getFileUrl(documentId);
}
