import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { ReadOnlyNotice } from '../../components/readOnlyNotice/readOnlyNotice';
import {
  StsInvoiceSaveOrDuplicateOrEdit as StsInvoiceSaveOrDuplicateOrEditComponent
} from '../../components/stsInvoiceSaveOrDuplicateOrEdit/stsInvoiceSaveOrDuplicateOrEdit';
import { StsProfileNotice } from '../../components/stsProfileNotice/stsProfileNotice';
import { InvoiceStatusType, PaymentTermsType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStsInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import { setStsInvoice, setStsInvoiceAteco, setStsInvoiceDate, setStsInvoiceFileList, setStsInvoiceNumber, setStsInvoicePaymentExpiration, setStsInvoicePaymentTerms, setStsInvoicePreviousStatus, setStsInvoiceStamp, setStsInvoiceStatus, setStsStampId } from '../../redux/slices/stsInvoiceSlice';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './stsInvoiceSaveOrDuplicateOrEdit.json';
import { setProformaInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice';

export function StsInvoiceSaveOrDuplicateOrEdit() {
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const stsInvoiceState = useAppSelector(state => state.stsInvoice);
  const stsInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.stsInvoiceSaveOrDuplicateOrEdit);
  const proformaInvoiceState = useAppSelector(state => state.proformaInvoice)
  const proformaInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.proformaInvoiceSaveOrDuplicateOrEdit)
  const userState = useAppSelector((state) => state.user.user);


  const dispatch = useAppDispatch();

  const { collapsed } = useProSidebar();

  const stsInvoice = stsInvoiceState.invoice
  const invoiceId = stsInvoiceSaveOrDuplicateOrEditState.invoiceId
  const operation = stsInvoiceSaveOrDuplicateOrEditState.operation
  const proformaInvoice = proformaInvoiceState.invoice
  const proformaInvoiceFile = proformaInvoiceState.fileList
  const operationProforma = proformaInvoiceSaveOrDuplicateOrEditState.operation

  useEffect(() => {
    if (operation === 'duplicate' || operation === 'edit') {
      findById(dispatch, setStatus, operation, invoiceId);
    }
  }, [invoiceId, operation]);

  useEffect(() => {
    if (invoiceId !== undefined && stsInvoiceSaveOrDuplicateOrEditState.editStatus === 'successfully' && operation !== 'save' && operationProforma !== 'convert') {
      findById(dispatch, setStatus, operation, invoiceId);
    }
  }, [stsInvoiceSaveOrDuplicateOrEditState.editStatus]);

  useEffect(() => {
    if ((proformaInvoiceState.findByIdStatus === 'successfully' && operationProforma === 'convert')) {
      dispatch(setStsInvoice(proformaInvoice))
      dispatch(setStsInvoiceNumber(undefined))
      dispatch(setStsInvoiceFileList(proformaInvoiceFile))
      dispatch(setStsInvoiceAteco(proformaInvoice.ateco))
      dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('save'))
      dispatch(setStsInvoiceDate(new Date()))
      dispatch(setStsInvoicePaymentExpiration(new Date()))
      dispatch(setStsInvoicePaymentTerms(PaymentTermsType.IMMEDIATE))
      dispatch(setStsInvoiceStatus(InvoiceStatusType.DRAFT))
      dispatch(setStsInvoicePreviousStatus(InvoiceStatusType.DRAFT))
      dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('save'))
    }
  }, [proformaInvoiceState.findByIdStatus])

  if (status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {lang.Error}
      </div>
    );
  }

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  const invoiceIsEditable = StsInvoiceService.isEditable(stsInvoice);
  const invoiceAlreadyTransmitted = StsInvoiceService.isSendable(stsInvoice);

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='sts-invoice-edit-menu'
            menuItem={MenuItems.CUSTOMER_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          {
            !invoiceIsEditable &&
            !invoiceAlreadyTransmitted &&
            operation === 'edit' && (
              <div className={'row my-4'}>
                <div className={'col-12'}>
                  <ReadOnlyNotice />
                </div>
              </div>
            )
          }
          {(stsInvoice.previousStatus !== InvoiceStatusType.ALREADY_TRANSMITTED) && (
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <StsProfileNotice />
              </div>
            </div>
          )}
          <div className={'row'}>
            <div className={'col-12'}>
              <StsInvoiceSaveOrDuplicateOrEditComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function findById(
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  operation: Operation,
  invoiceId?: string,
): void {
  if (!invoiceId) {
    return;
  }

  const service = new StsInvoiceService();

  setStatus('loading');
  service
    .findById(invoiceId)
    .then(data => {
      dispatch(setStsInvoice(data.invoice));
      dispatch(setStsInvoiceFileList(data.files));
      if (operation === 'duplicate') {
        dispatch(setStsInvoiceStatus(InvoiceStatusType.DRAFT))
      }

      setStatus('idle');
    })
    .catch(err => {
      console.error(err);
      setStatus('failed');
    });
}
