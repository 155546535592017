import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './customerProfileNotice.css';
import lang from './customerProfileNotice.json';

interface Props {
  isProforma?: boolean;
}

export function CustomerProfileNotice(props: Props) {
  const { isProforma } = props;

  return (
    <div className={'customer-profile-notice-container'}>
      <p className={'mb-0'}>
        <QuickfiscoIcon name={'alert-triangle-red.svg'} className={'pe-2'} />
        {!isProforma && lang.text1}
        {!isProforma ? (
          <span className={'customer-profile-notice-bold'}>
            {lang.boldText}
          </span>
        ) : (
          <b>{lang.boldProformaText}</b>
        )}
        {!isProforma && lang.text2}
      </p>
    </div>
  );
}
