import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { host } from '../../utils/config';
import lang from './incomeUploadModalStep2.json';
import './incomeUploadModalStep2.css';

interface Props {
  qfUsedFlag: boolean | undefined;
  onClickPrevStep: () => void;
  onClickNextStep: () => void;
}

export function IncomeUploadModalStep2(props: Props) {
  const { qfUsedFlag } = props;
  const { onClickPrevStep } = props;
  const { onClickNextStep } = props;

  return (
    <>
      {qfUsedFlag === true ? (
        <>
          <div className={'row'}>
            <div className={'col-12 income-upload-modal-step2-subtitle'}>
              {lang.subTitleYes}
            </div>
          </div>
          <div className={'row my-4'}>
            <div className={'col-12'}>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step2-text'}>
                  <span className={'income-upload-modal-step2-text-blue'}>
                    Se <b>hai utilizzato Quickfisco</b> per emettere{' '}
                    <b>TUTTE</b> le tue fatture nel 2023
                  </span>{' '}
                  <b>
                    verifica di aver aggiornato correttamente lo stato di
                    pagamento delle tue fatture.
                  </b>
                </div>
              </div>
              <div className={'row my-4'}>
                <div className={'col-6 offset-3'}>
                  <QuickfiscoButton
                    id={'income-upload-modal-step2-button'}
                    label={lang.goToInvoice}
                    type={'primary'}
                    onClick={() => {
                      window.open('/customer-invoice-list', '_blank');
                    }}
                  />
                </div>
              </div>
              <div className={'row mb-3'}>
                <div className={'col-12 income-upload-modal-step2-text-small'}>
                  <b>
                    Una volta aggiornato lo stato di pagamento delle fatture
                    emesse nel 2023
                  </b>
                  <br />
                  presenti su Quickfisco puoi{' '}
                  <b>passare allo step successivo</b>.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={'row'}>
            <div className={'col-12 income-upload-modal-step2-subtitle'}>
              {lang.subTitleNo}
            </div>
          </div>
          <div className={'row my-4'}>
            <div className={'col-12'}>
              <div className="row mt-1 mb-4">
                <div className="col-4 offset-4">
                  <a
                    href={
                      host +
                      '/api/v1/invoices/customer/excel?year=' +
                      (new Date().getFullYear() - 1)
                    }
                    rel={'noreferrer'}
                  >
                    <QuickfiscoButton
                      id={'tax-declaration-procedure'}
                      label={`DOWNLOAD EXCEL`}
                      type={'primary'}
                    />
                  </a>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step2-text'}>
                  <span className={'income-upload-modal-step2-text-blue'}>
                    Se <b>NON hai utilizzato Quickfisco</b> per emettere{' '}
                    <b>TUTTE</b> le tue fatture nel 2023
                  </span>{' '}
                  <b>
                    scarica il file excel dal pulsante sopra e inserisci i dati
                    delle tue fatture incassate nel 2023.
                  </b>
                </div>
              </div>
              <div className={'row my-3'}>
                <div className={'col-12 income-upload-modal-step2-text-small'}>
                  Presta attenzione ad{' '}
                  <b>
                    eventuali fatture emesse in anni precedenti ma incassate nel
                    2023
                  </b>
                  , devi segnalare anche queste nel file.
                  <br />
                  Se hai incassato un elevato numero di fatture (es. + di 50), e
                  hai un tuo file excel dove hai traccia degli incassi passa
                  direttamente allo step successivo.
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={'row no-gutters my-4'}>
        <div className={'col-3 d-flex justify-content-center'}>
          <QuickfiscoButton
            id={'income-upload-modal-step2-button'}
            label={lang.step2ButtonPrev}
            type={'primary'}
            onClick={() => onClickPrevStep()}
          />
        </div>
        <div className={'col-3 offset-6 d-flex justify-content-center'}>
          <QuickfiscoButton
            id={'income-upload-modal-step2-button'}
            label={lang.step2buttonNext}
            type={qfUsedFlag !== undefined ? 'secondary' : 'disabled'}
            onClick={() => onClickNextStep()}
          />
        </div>
      </div>
    </>
  );
}
