import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { YouSignIframe } from '../../components/youSignIframe/youSignIframe';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { ContractSignatureService } from '../../services/contractSignatureService';
import { PromiseStatuses } from '../../types/strings';
import { UserRegistrationModel } from '../../models/onboardingModel';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './contractOnboarding.json';
import './contractOnboarding.css';

export function ContractOnboarding() {
  const [signatureLinkStatus, setSignatureLinkStatus] =
    useState<PromiseStatuses>('idle');
  const [signatureLink, setSignatureLink] = useState<string | undefined>(
    undefined
  );
  const [youSignSignatureRequestId, setYouSignSignatureRequestId] = useState<
    string | undefined
  >(undefined);

  const [editStatus, setEditStatus] = useState<PromiseStatuses>('idle');

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.editUserRequest);

  const dataToSend: UserRegistrationModel = {
    id: user.id,
    youSignSignatureRequestId,
  };

  useEffect(() => {
    getSignatureLinkFunc();
  }, []);

  useEffect(() => {
    if (user.registrationSteps?.contract === true) {
      navigate(uri.Payment);
    }
  }, [user]);

  const getSignatureLinkFunc = () => {
    const contractSignatureService = new ContractSignatureService();
    setSignatureLinkStatus('loading');
    contractSignatureService
      .getSignatureLink()
      .then((key) => {
        setSignatureLink(key);
        setSignatureLinkStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setSignatureLinkStatus('failed');
      });
  };

  if (signatureLinkStatus === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (signatureLinkStatus === 'failed' || editStatus === 'failed') {
    return (
      <OnboardingApiError
        statusFailed={
          signatureLinkStatus === 'failed' || editStatus === 'failed'
        }
      />
    );
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="contract-onboarding-main-title">
                04. <span>{lang.mainTitle}</span>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-8 offset-2 contract-onboarding-container box-shadow py-5'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-10 offset-1'}>
                  <div className="row text-center mb-2">
                    <div className="col-12 contract-onboarding-instruction">
                      Di seguito trovi i <b>contratti da firmare</b> per aderire
                      al servizio di Quickfisco
                    </div>
                  </div>
                  {signatureLink && (
                    <div className="row my-3">
                      <div className="col-12">
                        <YouSignIframe
                          signatureLink={signatureLink}
                          onSuccessSignature={(
                            youSignSignatureRequestId: string
                          ) => {
                            setYouSignSignatureRequestId(
                              youSignSignatureRequestId
                            );
                            update(
                              { ...dataToSend, youSignSignatureRequestId },
                              navigate,
                              setEditStatus
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-3">
                    <div className="col-4 d-flex justify-content-center align-items-center">
                      <QuickfiscoButton
                        type={'primary'}
                        id="contract-onboarding-go-back"
                        label={lang.goBack}
                        onClick={() => navigate(uri.Documents)}
                      />
                    </div>
                    <div className="col-4 offset-4 d-flex justify-content-center align-items-center">
                      {editStatus === 'loading' ? (
                        <QuickfiscoSpinner />
                      ) : (
                        <QuickfiscoButton
                          type={
                            youSignSignatureRequestId ? 'secondary' : 'disabled'
                          }
                          id="contract-onboarding-edit"
                          label={lang.goNext}
                          onClick={() => navigate(uri.Payment)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function update(
  user: UserRegistrationModel,
  navigate: NavigateFunction,
  setStatus: SetState<PromiseStatuses>
) {
  if (user.id) {
    const userService = new UserService();

    setStatus('loading');

    userService
      .onboardingUpdateUser({ ...user, registrationStep: 'contract' })
      .then(() => {
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }
}
