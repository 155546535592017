import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../../components/quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import { QuickfiscoLink } from '../../components/quickfiscoLink/quickfiscoLink';
import {
  LogoDim,
  QuickfiscoLogo,
} from '../../components/quickfiscoLogo/quickfiscoLogo';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { RegisterModel } from '../../models/registerModel';
import { UserErrorSetters } from '../../models/userErrorSetters';
import { useAppDispatch } from '../../redux/hooks';
import { UserService } from '../../services/userService';
import { doLogin } from '../../redux/slices/loginSlice';
import TagManager from 'react-gtm-module';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoError } from '../../components/quickfiscoError/quickfiscoError';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import uri from '../../routers/quickfiscoUri.json';
import './register.css';
import lang from './register.json';

export function Register() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorSurname, setErrorSurname] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [statusRegistration, setStatusRegistration] =
    useState<PromiseStatuses>('idle');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const registerService = new UserService(undefined, {
    setErrorName,
    setErrorSurname,
    setErrorEmail,
    setErrorPhone,
    setErrorPassword,
    setErrorConfirmPassword,
    setErrorPrivacy,
    setErrorTerms,
  });

  return (
    <>
      <ViewportWarning />
      <QuickfiscoError
        message={lang.registerError}
        active={statusRegistration === 'failed'}
        close={() => setStatusRegistration('idle')}
      />
      <div className="row full-screen mr-0">
        <div className="col-12 col-xl-9 register-left-side">
          <div className="row align-items-center">
            <div className="col-4">
              <QuickfiscoLogo id="register-logo" dim={LogoDim.SMALL} />
            </div>
            <div className="col-4 offset-4 d-flex justify-content-end">
              <div className="register-link-container">
                <span className="me-1 register-signed">{lang.SignedLabel}</span>
                <QuickfiscoLink
                  id={'register-go-to-login'}
                  label={lang.LoginLabel}
                  onClick={() => navigate(uri.Login)}
                />
              </div>
            </div>
          </div>
          <div className="p-5">
            <h2 className={'register-title mt-3 mb-4'}>{lang.TitlePage}</h2>
            <div className="row">
              <div className="col-6">
                <QuickfiscoInput
                  id="registerName"
                  label={lang.NameLabel}
                  type="text"
                  placeholder={lang.NamePlaceholder}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  error={errorName}
                  errorLabel={lang.ErrorName}
                  required={true}
                  styleType={'default'}
                  onBlur={(e) => registerService.validateName(e.target.value)}
                />
              </div>
              <div className="col-6">
                <QuickfiscoInput
                  id="registerSurname"
                  label={lang.SurnameLabel}
                  type="text"
                  styleType={'default'}
                  placeholder={lang.SurnamePlaceholder}
                  onChange={(e) => setSurname(e.target.value)}
                  value={surname}
                  error={errorSurname}
                  errorLabel={lang.ErrorSurname}
                  required={true}
                  onBlur={(e) =>
                    registerService.validateSurname(e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <QuickfiscoInput
                  id="registerEmail"
                  label={lang.EmailLabel}
                  styleType={'default'}
                  type="email"
                  placeholder={lang.EmailPlaceholder}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  error={errorEmail}
                  errorLabel={lang.ErrorEmail}
                  required={true}
                  onBlur={(e) =>
                    registerService.validateEmail(e.target.value, {
                      confirmPassword: confirmPassword,
                    })
                  }
                />
              </div>
              <div className="col-6">
                <QuickfiscoInput
                  id="registerPhone"
                  label={lang.PhoneLabel}
                  type="text"
                  styleType={'default'}
                  placeholder={lang.PhonePlaceholder}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  required={true}
                  error={errorPhone}
                  errorLabel={lang.ErrorPhone}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <QuickfiscoInput
                  id="registerPassword"
                  label={lang.PasswordLabel}
                  type="password"
                  styleType={'default'}
                  placeholder={lang.PasswordPlaceholder}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  error={errorPassword}
                  errorLabel={lang.ErrorPassword}
                  required={true}
                  onBlur={(e) =>
                    registerService.validatePasswordInput(
                      { confirmPassword: confirmPassword },
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-6">
                <QuickfiscoInput
                  id="registerConfirmPassword"
                  label={lang.ConfirmPasswordLabel}
                  type="password"
                  placeholder={lang.ConfirmPasswordPlaceholder}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  error={errorConfirmPassword}
                  styleType={'default'}
                  errorLabel={lang.ErrorConfirmPassword}
                  required={true}
                  onBlur={(e) =>
                    registerService.validateConfirmPasswordInput(
                      { confirmPassword: e.target.value },
                      password
                    )
                  }
                />
              </div>
            </div>
            <div className={'row mt-4 register-privacy-container'}>
              <div className={'col-6'}>
                <QuickfiscoInput
                  id="promoCode"
                  label={lang.PromoCodeLabel}
                  type="text"
                  placeholder={lang.PromoCodePlaceholder}
                  styleType={'no-borders'}
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={promoCode}
                  required={false}
                />
              </div>
            </div>
            <div className={'row mt-4 register-privacy-container'}>
              <div className={'col-12'}>
                <div className="row no-gutters">
                  <div className="col-12 no-gutters">
                    <QuickfiscoCheckbox
                      id="registerMarketing"
                      label={lang.MarketingLabel}
                      onChange={(e) => setMarketing(e.target.checked)}
                      checked={marketing}
                      linkLabel={lang.MarketingLabelLink}
                      link={config.marketing}
                    />
                  </div>
                </div>
                <div className="row no-gutters mt-3">
                  <div className="col-12 no-gutters">
                    <QuickfiscoCheckbox
                      id="registerPrivacy"
                      label={lang.PrivacyLabel}
                      onChange={(e) => setPrivacy(e.target.checked)}
                      checked={privacy}
                      error={errorPrivacy}
                      linkLabel={lang.PrivacyLabelLink}
                      link={config.privacy}
                      required={true}
                      onBlur={(e) =>
                        registerService.validatePrivacy(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className="row no-gutters mt-3">
                  <div className="col-12 no-gutters">
                    <QuickfiscoCheckbox
                      id="registerTerms"
                      label={lang.TermsLabel}
                      onChange={(e) => setTerms(e.target.checked)}
                      checked={terms}
                      error={errorTerms}
                      linkLabel={lang.TermsLabelLink}
                      link={config.terms}
                      required={true}
                      onBlur={(e) =>
                        registerService.validateTerms(e.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5" />
            <div className="row">
              <div className="col-4">
                {statusRegistration === 'loading' ? (
                  <div className="text-center">
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id="register-btn"
                    label={lang.RegisterLabel}
                    type={'primary'}
                    onClick={() => {
                      doRegistration(
                        {
                          name,
                          surname,
                          password,
                          email,
                          phone,
                          promoCode,
                          privacy,
                          terms,
                          marketing,
                        },
                        confirmPassword,
                        navigate,
                        {
                          setErrorName,
                          setErrorSurname,
                          setErrorEmail,
                          setErrorPhone,
                          setErrorPassword,
                          setErrorConfirmPassword,
                          setErrorPrivacy,
                          setErrorTerms,
                        },
                        dispatch,
                        setStatusRegistration
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-xl-flex col-3 register-right-side">
          <div>
            <h2>{lang.Welcome}</h2>
            <QuickfiscoIcon name={'bang.png'} />
          </div>
        </div>
      </div>
    </>
  );
}

async function doRegistration(
  register: RegisterModel,
  confirmPassword: string,
  navigate: NavigateFunction,
  errorSetters: UserErrorSetters,
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>
) {
  const service = new UserService(undefined, errorSetters);

  if (
    !(await service.validateUser(
      { ...register, atecos: [], bankAccount: [] },
      { confirmPassword: confirmPassword }
    ))
  ) {
    return;
  }

  const registerService = new UserService();

  setStatus('loading');

  registerService
    .doRegistration(register)
    .then(() => {
      setStatus('successfully');
      dispatch(
        doLogin({
          email: register.email,
          password: register.password,
        })
      );
      TagManager.dataLayer({
        dataLayer: {
          event: 'free_trial',
          email: register.email,
        },
      });
      navigate(uri.Home);
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
