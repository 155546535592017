import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { v4 as uiidv4 } from 'uuid';
import { ContactType } from '../../models/contactModel';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { InvoiceModel, InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editAutoInvoice, setAutoInvoiceSaveOrDuplicateOrEditInvoiceId, setAutoInvoiceSaveOrDuplicateOrEditOperation, setAutoInvoiceSaveOrDuplicateOrEditSuccessfullySaved } from '../../redux/slices/autoInvoiceSaveOrDuplicateOrEditSlice';
import { setAutoInvoiceDate, setAutoInvoiceDocumentType, setAutoInvoiceFileList, setAutoInvoiceGoodServices, setAutoInvoiceNotes, setAutoInvoicePaymentExpiration, setAutoInvoiceStatus, setAutoInvoiceSupplier } from '../../redux/slices/autoInvoiceSlice';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import uri from '../../routers/quickfiscoUri.json';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { downloadPdf, validateFiles } from '../../utils/file';
import { AlreadyTransmittedCheckbox } from '../alreadyTransmittedCheckbox/alreadyTransmittedCheckbox';
import { ContactSelect } from '../contactSelect/contactSelect';
import { DocumentTypeSelect } from '../documentTypeSelect/documentTypeSelect';
import { GoodServicesInput } from '../goodServicesInput/goodServicesInput';
import { InvoiceAmount } from '../invoiceAmount/invoiceAmount';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { SeePreviewPdfModal } from '../seePreviewPdfModal/seePreviewPdfModal';
import { SendInvoice } from '../sendInvoice/sendInvoice';
import { SendTrialInvoiceModal } from '../sendTrialInvoiceModal/sendTrialInvoiceModal';
import { UserStatusType } from '../../models/userModel';
import lang from './autoInvoiceSaveOrDuplicateOrEdit.json';
import './autoInvoiceSaveOrDuplicateOrEdit.css';

export function AutoInvoiceSaveOrDuplicateOrEdit() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] = useState<PromiseStatuses>('idle');
  const [sendSectionOpen, setSendSectionOpen] = useState(false);
  const [seePreviewPdfOpen, setSeePreviewPdfOpen] = useState(false);
  const [sendTrialInvoiceOpen, setSendTrialInvoiceOpen] = useState(false);

  // TODO remove
  const [errorSupplier, setErrorSupplier] = useState(false);
  const [errorGoodServices, setErrorGoodServices] = useState(false);
  const [errorGoodServicesInput, setErrorGoodServicesInput] = useState(false);
  const [errorDocumentType, setErrorDocumentType] = useState(false);
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorDateMessage, setErrorDateMessage] = useState(lang.DateError);

  const service = new AutoInvoiceService(
    {
      setErrorCustomer: setErrorSupplier,
      setErrorGoodServicesInput,
      setErrorGoodServices,
      setErrorDocumentType,
      setErrorDate,
      setErrorDateMessage
    }
  );
  // TODO remove

  const userState = useAppSelector(state => state.user);
  const autoInvoiceState = useAppSelector(state => state.autoInvoice);
  const autoInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.autoInvoiceSaveOrDuplicateOrEdit)
  const globalStatus = useAppSelector(state => state.operationStatus.status);
  const globalErrorMessage = useAppSelector(state => state.operationStatus.errorMessage);

  const user = userState.user
  const invoice = autoInvoiceState.invoice
  const fileList = autoInvoiceState.fileList
  const operation = autoInvoiceSaveOrDuplicateOrEditState.operation
  const successfullySaved = autoInvoiceSaveOrDuplicateOrEditState.successfullySaved

  const [userIsNotEnabled, setUserIsNotEnabled] = useState(UserService.isNotEnabled(user))
  const [invoiceIsEditable, setInvoiceIsEditable] = useState(AutoInvoiceService.isEditable(invoice))
  const [invoiceIsSendable, setInvoiceIsSendable] = useState(AutoInvoiceService.isSendable(invoice))

  useEffect(() => {
    if (
      operation === 'duplicate' &&
      invoice.status !== InvoiceStatusType.DRAFT &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED
    ) {
      dispatch(setAutoInvoiceStatus(InvoiceStatusType.DRAFT))
    }
  }, [invoice, operation])

  useEffect(() => {
    setUserIsNotEnabled(UserService.isNotEnabled(user))
  }, [user.enabled, user.status])

  useEffect(() => {
    setInvoiceIsEditable(AutoInvoiceService.isEditable(invoice))
    setInvoiceIsSendable(AutoInvoiceService.isSendable(invoice))
  }, [invoice.status])

  if (userState.status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati.
      </div>
    )
  }

  if (userState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        message={lang.SaveSuccess}
        active={successfullySaved}
        close={() => dispatch(setAutoInvoiceSaveOrDuplicateOrEditSuccessfullySaved(false))}
      />
      <QuickfiscoSuccess
        message={lang.EditSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={lang.SavingError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={globalErrorMessage === undefined ? lang.SendError : globalErrorMessage}
        active={globalStatus === 'failed'}
        close={() => dispatch(setGlobalStatus({
          status: 'idle'
        }))}
      />
      <QuickfiscoError
        message={lang.DownloadPdfError}
        active={downloadPdfStatus === 'failed'}
        close={() => setDownloadPdfStatus('idle')}
      />
      <div className={'col-12 col-xl-5 mt-4'}>
        <div className={'row no-gutters'}>
          <div className={'col-12 no-gutters'}>
            <ContactSelect
              type={ContactType.SUPPLIER}
              selected={invoice.supplier}
              setContact={contact => {
                dispatch(setAutoInvoiceSupplier(contact));
                service.validateContact(contact);
              }}
              error={errorSupplier}
              requiredField={true}
              disabled={!invoiceIsEditable}
            />
          </div>
        </div>
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 invoice-customer-saveOrEdit-container py-3 ps-4 pe-4'}>
            <AlreadyTransmittedCheckbox
              id='auto-invoice-already-transmitted-input'
              value={invoice.status}
              disabled={operation === 'edit'}
              onChange={e => {
                dispatch(setAutoInvoiceStatus(e.target.checked ? InvoiceStatusType.ALREADY_TRANSMITTED : InvoiceStatusType.DRAFT));
              }}
            />
          </div>
        </div>
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 invoice-auto-saveOrEdit-container p-4'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <DocumentTypeSelect
                  id='auto-invoice-document-type-select'
                  disabled={!invoiceIsEditable}
                  invoiceSign={'auto'}
                  onChange={(_type) => dispatch(setAutoInvoiceDocumentType(_type))}
                  value={invoice.type}
                  error={errorDocumentType}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col'}>
                <QuickfiscoDatePicker
                  id={'invoice-auto-save-date'}
                  label={lang.line2}
                  onChange={value => {
                    value && dispatch(setAutoInvoiceDate(resolveUndefinedDate(formatDateUS(value))));
                    value && dispatch(setAutoInvoicePaymentExpiration((resolveUndefinedDate(formatDateUS(value)))))
                  }}
                  value={new Date(invoice.date)}
                  required={true}
                  disabled={!invoiceIsEditable}
                  error={errorDate}
                  errorLabel={errorDateMessage}
                  maxDate={new Date()}
                  styleType={'default'}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <QuickfiscoTextarea
                id={'invoice-auto-save-notes'}
                label={lang.line8}
                onChange={e => dispatch(setAutoInvoiceNotes(e.target.value))}
                value={invoice.notes === null ? undefined : invoice.notes}
                placeholder={lang.placeholderNote}
                secondaryLabel={lang.secondaryLabel}
                disabled={!invoiceIsEditable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'col-12 col-xl-7 mt-4'}>
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-auto-saveOrEdit-container p-4'}>
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <GoodServicesInput
                  goodServices={(invoice.goodServices !== undefined && invoice.goodServices !== null && invoice.goodServices.length > 0) ? invoice.goodServices : [{
                    key: uiidv4(),
                    quantity: 1,
                    amount: 0
                  }]}
                  setGoodServices={_goodServices => {
                    dispatch(setAutoInvoiceGoodServices(_goodServices));
                  }}
                  invoiceSign={'auto'}
                  error={errorGoodServices || errorGoodServicesInput}
                  errorLabel={errorGoodServices ? lang.GoodServiceError : lang.GoodServiceInputError}
                  disabled={!invoiceIsEditable}
                />
              </div>
            </div>
            <div className={'mt-5'}>
              <InvoiceAmount
                invoice={invoice}
                invoiceSign={'auto'}
              />
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <QuickfiscoInputFile
                  preview={true}
                  multiple={true}
                  maxHeight={'300px'}
                  fileList={fileList}
                  disabled={!invoiceIsEditable}
                  setFileList={_fileList => {
                    dispatch(setAutoInvoiceFileList([
                      ..._fileList
                    ]));
                  }}
                  error={errorFiles}
                  errorLabel={lang.filesError}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-4'}>
                {
                  status === 'loading' ? (
                    <div className={'w-100 d-flex justify-content-center align-items-center'}>
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'invoice-auto-save-button'}
                      label={lang.line14}
                      onClick={() => saveOrEdit(
                        invoice,
                        setStatus,
                        {
                          setErrorCustomer: setErrorSupplier,
                          setErrorGoodServices,
                          setErrorGoodServicesInput,
                          setErrorDocumentType,
                          setErrorDateMessage,
                          setErrorDate
                        },
                        operation,
                        dispatch,
                        fileList,
                        setErrorFiles
                      )}
                      type={(userIsNotEnabled || !invoiceIsEditable) ? 'disabled' : 'secondary'}
                    />
                  )
                }
              </div>
              <div className={'col-4'}>
                  <QuickfiscoButton
                    id={'invoice-auto-pdf-button'}
                    label={lang.line13}
                    type={userIsNotEnabled || operation !== 'edit' ? 'disabled' : 'primary'}
                    onClick={() => setSeePreviewPdfOpen(true)}
                  />
              </div>
              <div className={'col-4'}>
                <QuickfiscoButton
                  id={'invoice-auto-send-button'}
                  label={lang.line15}
                  type={userIsNotEnabled || operation !== 'edit' || !invoiceIsSendable ? 'disabled' : 'primary'}
                  onClick={() => {
                    if(user.status === UserStatusType.CONFIRMED) {
                      setSendTrialInvoiceOpen(true);
                    } else {
                      setSendSectionOpen(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SeePreviewPdfModal
        open={seePreviewPdfOpen}
        setOpen={(open: boolean) => setSeePreviewPdfOpen(open)}
        invoiceSign={'auto'}
        onClick={() => {
          getPdf(
            setDownloadPdfStatus,
            invoice.id,
            invoice.number,
            invoice.supplier?.companyName
          )
        }}
        downloadPdfStatus={downloadPdfStatus}
      />
      <SendInvoice
        open={sendSectionOpen}
        setOpen={(open: boolean) => setSendSectionOpen(open)}
        loading={globalStatus === 'loading'}
        onClick={() => send(
          dispatch,
          navigate,
          setSendSectionOpen,
          invoice.id
        )}
      />
      <SendTrialInvoiceModal
        open={sendTrialInvoiceOpen}
        setOpen={(open: boolean) => setSendTrialInvoiceOpen(open)}
        type={'invoice'}
      />
    </div>
  );
}

function saveOrEdit(
  invoice: InvoiceModel,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters,
  operation: Operation,
  dispatch: Function,
  fileList: File[],
  setErrorFiles: SetState<boolean>
): void {
  setErrorFiles(false);

  const service = new AutoInvoiceService(errorSetters);

  if (!service.validate(invoice, 'auto')) {
    return;
  }

  if (!validateFiles(fileList)) {
    setErrorFiles(true);
    return;
  }

  if (operation === 'duplicate' || operation === 'save') {
    setStatus('loading');
    service
      .save({ ...invoice }, [...fileList], [])
      .then(id => {
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditInvoiceId(id));
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditOperation('edit'));
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditSuccessfullySaved(true));
      })
      .catch(err => handleSaveOrEditError(err, setStatus, errorSetters));
  } else {
    dispatch(editAutoInvoice({ invoice: { ...invoice }, fileList: [...fileList], errorSetters: errorSetters }))
    setStatus('successfully')
  }
}

function handleSaveOrEditError(
  err: any,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters,
): void {
  console.error(err);

  err
    .json()
    .then((data: any) => {
      if (data.message === 'error.invalid-date') {
        errorSetters.setErrorDateMessage && errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.');
        errorSetters.setErrorDate && errorSetters.setErrorDate(true);
      }
    });

  setStatus('failed');
}

function getPdf(
  setStatus: SetState<PromiseStatuses>,
  invoiceId?: string,
  invoiceNumber?: string,
  invoiceCompanyName?: string
): void {
  if (!invoiceId) {
    return;
  }

  const service = new AutoInvoiceService();

  setStatus('loading');
  service
    .getPdf(invoiceId)
    .then(data => {
      setStatus('successfully');
      downloadPdf(AutoInvoiceService.getPdfName('auto', undefined, invoiceNumber, invoiceCompanyName), data, 'application/pdf');
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}

function send(
  dispatch: Function,
  navigate: NavigateFunction,
  setModalOpen: SetState<boolean>,
  invoiceId?: string
): void {
  if (!invoiceId) {
    return;
  }

  const service = new AutoInvoiceService();

  dispatch(setGlobalStatus({
    status: 'loading'
  }));
  service
    .send(invoiceId)
    .then(() => {
      dispatch(setGlobalStatus({
        status: 'successfully'
      }));
      navigate(uri.AutoInvoice);
    })
    .catch(err => {
      console.error(err);

      err
        .json()
        .then((data: any) => {
          if (data.message === 'error.invalid-date') {
            dispatch(setGlobalStatus({
              status: 'failed',
              errorMessage: lang.invalidInvoiceError
            }));
          }
        });

      setModalOpen(false);
    });
}
