import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoInputDoc.css';

interface Props {
  docIsPresent: boolean;
  onChange: (doc: File | null) => void;
  error?: boolean;
  errorLabel?: string;
  deleteDoc: () => void;
  disabled?: boolean;
}

export function QuickfiscoInputDoc(props: Props) {
  const { docIsPresent } = props;
  const { onChange } = props;
  const { deleteDoc } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { disabled } = props;

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  return (
    <>
      {error && errorLabel && (
        <QuickfiscoError message={errorLabel} active={error} close={() => {}} />
      )}
      {!docIsPresent ? (
        <div className="col-4 d-flex justify-content-center">
          <div onClick={() => inputRef?.click()}>
            <QuickfiscoIcon name={'new.svg'} className="cursor-pointer" />
          </div>
          <input
            type="file"
            className={'d-none'}
            accept={'.pdf, .jpg, .jpeg, .png'}
            ref={(ref) => setInputRef(ref)}
            onChange={(e) => onChange(e.target.files && e.target.files.item(0))}
          />
        </div>
      ) : (
        <div
          className="col-2 d-flex justify-content-center quickfisco-input-doc-remove"
          onClick={() => deleteDoc()}
        >
          {!disabled && (
            <QuickfiscoIcon
              name={'delete-negative.svg'}
              className="cursor-pointer"
            />
          )}
        </div>
      )}
    </>
  );
}
