import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';
import {
  CustomerInvoiceSaveOrDuplicateOrEdit as CustomerInvoiceSaveOrDuplicateOrEditComponent
} from '../../components/customerInvoiceSaveOrDuplicateOrEdit/customerInvoiceSaveOrDuplicateOrEdit';
import { CustomerProfileNotice } from '../../components/customerProfileNotice/customerProfileNotice';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { ReadOnlyNotice } from '../../components/readOnlyNotice/readOnlyNotice';
import { InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCustomerInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/customerInvoiceSaveOrDuplicateOrEditSlice';
import {
  findCustomerInvoiceById, setCustomerInvoice,
  setCustomerInvoiceAteco,
  setCustomerInvoiceDate,
  setCustomerInvoiceFileList,
  setCustomerInvoiceNumber,
  setCustomerInvoicePaymentExpiration,
  setCustomerInvoicePreviousStatus,
  setCustomerInvoiceReimbursementsOfExpenses,
  setCustomerInvoiceStatus
} from '../../redux/slices/customerInvoiceSlice';
import { setProformaInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice';
import uri from '../../routers/quickfiscoUri.json';
import { getDateFromPaymentTerms } from '../../utils/date';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './customerInvoiceSaveOrDuplicateOrEdit.json';

export function CustomerInvoiceSaveOrDuplicateOrEdit() {
  const location = useLocation();

  const customerInvoiceState = useAppSelector(state => state.customerInvoice);
  const customerInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.customerInvoiceSaveOrDuplicateOrEdit);
  const proformaInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.proformaInvoiceSaveOrDuplicateOrEdit)
  const proformaInvoiceState = useAppSelector(state => state.proformaInvoice)
  const user = useAppSelector(state => state.user.user)

  const dispatch = useAppDispatch();

  const { collapsed } = useProSidebar();

  const customerInvoice = customerInvoiceState.invoice
  const proformaInvoice = proformaInvoiceState.invoice
  const proformaInvoiceFile = proformaInvoiceState.fileList
  const proformaInvoiceReimbursementsOfExpenses = proformaInvoiceState.reimbursementsOfExpenses
  const invoiceId = customerInvoiceSaveOrDuplicateOrEditState.invoiceId
  const operation = customerInvoiceSaveOrDuplicateOrEditState.operation
  const operationProforma = proformaInvoiceSaveOrDuplicateOrEditState.operation

  useEffect(() => {
    if (invoiceId !== undefined && (operation === 'duplicate' || operation === 'edit')) {
      dispatch(findCustomerInvoiceById(invoiceId))
    }
  }, [invoiceId, operation]);

  useEffect(() => {
    if (invoiceId !== undefined && customerInvoiceSaveOrDuplicateOrEditState.editStatus === 'successfully' && operation !== 'save' && operationProforma !== 'convert') {
      dispatch(findCustomerInvoiceById(invoiceId))
    }
  }, [customerInvoiceSaveOrDuplicateOrEditState.editStatus]);


  useEffect(() => {
    if (proformaInvoiceState.findByIdStatus === 'successfully' && operationProforma === 'convert') {
      dispatch(setCustomerInvoice(proformaInvoice))
      dispatch(setCustomerInvoiceNumber(undefined))
      dispatch(setCustomerInvoiceFileList(proformaInvoiceFile))
      dispatch(setCustomerInvoiceReimbursementsOfExpenses(proformaInvoiceReimbursementsOfExpenses))
      dispatch(setCustomerInvoiceAteco(proformaInvoice.ateco))
      dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('save'))
      dispatch(setCustomerInvoiceDate(new Date()))
      dispatch(setCustomerInvoicePaymentExpiration(getDateFromPaymentTerms(new Date(), proformaInvoice.paymentTerms)))
      dispatch(setCustomerInvoiceStatus(InvoiceStatusType.DRAFT))
      dispatch(setCustomerInvoicePreviousStatus(InvoiceStatusType.DRAFT))
      dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('save'))
    }
  }, [proformaInvoiceState.findByIdStatus])

  if (customerInvoiceState.findByIdStatus === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (customerInvoiceState.findByIdStatus === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {location.pathname !== uri.CreditSlipInvoiceSave ? lang.ErrorCustomer : lang.ErrorCreditSlip}
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='customer-invoice-edit-menu'
            menuItem={location.pathname !== uri.CreditSlipInvoiceSave ? MenuItems.CUSTOMER_INVOICE : MenuItems.CREDIT_SLIP}
          />
        </div>
        <div className='col p-4 ps-5' style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={location.pathname !== uri.CreditSlipInvoiceSave ? lang.TitleCustomer : lang.TitleCreditSlip} />
              {user.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          {
            customerInvoice.status !== InvoiceStatusType.DRAFT &&
            customerInvoice.status !== InvoiceStatusType.REJECTED &&
            customerInvoice.status !== InvoiceStatusType.QUEUE_NACK &&
            operation === 'edit' && (
              <div className={`row ${location.pathname !== uri.CreditSlipInvoiceSave ? 'my-4' : undefined}`}>
                <div className={'col-12'}>
                  <ReadOnlyNotice />
                </div>
              </div>
            )
          }
          {
            (user.stsEnabled &&
              location.pathname !== uri.CreditSlipInvoiceSave &&
              (customerInvoice.previousStatus !== InvoiceStatusType.ALREADY_TRANSMITTED)) && (
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <CustomerProfileNotice />
                </div>
              </div>
            )
          }
          <div className={'row'}>
            <div className={'col-12'}>
              <CustomerInvoiceSaveOrDuplicateOrEditComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
