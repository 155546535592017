import {
  ProfileStatus,
  StsProfileModel
} from '../models/stsProfileModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class StsProfileService {

  private readonly url = host + '/api/v1/sts-profile';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public get(): Promise<StsProfileModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url
    })
      .then(res => res.json())
      .then((data: StsProfileModel) => data)
      .catch(err => Promise.reject(err.status));
  }

  public save(profile: StsProfileModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url,
      body: JSON.stringify(profile),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public edit(profile: StsProfileModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url,
      body: JSON.stringify(profile),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public del(): Promise<void> {
    return this.http.sendAndReceive({
      method: 'delete',
      url: this.url
    })
      .then(() => undefined);
  }

  public changeStatus(status: ProfileStatus): Promise<void> {
    const body = { status: status };

    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + '/status',
      body: JSON.stringify(body),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public validatePassword(password?: string): boolean {
    return password !== undefined && password !== '';
  }

  public validatePincode(pincode?: string): boolean {
    return pincode !== undefined && pincode !== '';
  }

  public validateIdentificationCode(identificationCode?: string): boolean {
    return identificationCode !== undefined && identificationCode !== '';
  }

  public validate(profile?: StsProfileModel): boolean {
    let isValid = true;

    if (!this.validatePassword(profile?.password)) {
      isValid = false;
    }
    if (!this.validatePincode(profile?.pinCode)) {
      isValid = false;
    }
    if (!this.validateIdentificationCode(profile?.identificationCode)) {
      isValid = false;
    }

    return isValid;
  }

}
