import { OnChange } from '../../types/functions';
import './quickfiscoInputRadio.css';

interface Props {
  id: string;
  value: string;
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: OnChange;
  error?: boolean;
}

export function QuickfiscoInputRadio(props: Props) {
  const { id } = props;
  const { name } = props;
  const { label } = props;
  const { checked } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;

  return (
    <div className="mb-3 w-100 d-flex align-items-start">
      <div>
        <input
          id={id}
          className="form-check-input quickfisco-input-radio"
          type={'radio'}
          name={name}
          checked={checked}
          value={value}
          onChange={(e) => onChange && onChange(e)}
        />
      </div>
      <div>
        <label
          className={error ? 'quickfisco-input-radio-label-error ms-2' : 'quickfisco-input-radio-label ms-2'}
        >
          {label}
        </label>
      </div>
    </div>
  );
}
