import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTaxProfile } from '../../redux/slices/taxProfileSlice';
import lang from '../../views/taxCalculation/taxCalculation.json';
import { QuickfiscoHeader } from '../quickfiscoHeader/quickfiscoHeader';
import { MenuItems } from '../quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxCalculationMainteinance } from '../taxCalculationMainteinance/taxCalculationMainteinance';
import { TaxForecast } from '../taxForecast/taxForecast';
import { TaxIncompleteProfile } from '../taxIncompleteProfile/taxIncompleteProfile';
import { TaxProfileCompleted } from '../taxProfileCompleted/taxProfileCompleted';
import { TaxProfileConfirmData } from '../taxProfileConfirmData/taxProfileConfirmData';
import { TaxProfileCurrentYearData } from '../taxProfileCurrentYearData/taxProfileCurrentYearData';
import { TaxProfileFourthStep } from '../taxProfileFourthStep/taxProfileFourthStep';
import { TaxProfileLastYearData } from '../taxProfileLastYearData/taxProfileLastYearData';
import { TaxProfileVatOpeningDate } from '../taxProfileVatOpeningDate/taxProfileVatOpeningDate';
import { TaxProfileWelcome } from '../taxProfileWelcome/taxProfileWelcome';
import { TaxProfileWizard } from '../taxProfileWizard/taxProfileWizard';
import { TaxTrialUser } from '../taxTrialUser/taxTrialUser';

export function TaxCalculation() {
  const userState = useAppSelector(state => state.user);
  const taxProfileState = useAppSelector(state => state.taxProfile);

  const dispatch = useAppDispatch();

  let status: boolean = true

  useEffect(() => {
    dispatch(getTaxProfile());
  }, []);

  if (false) {
    return (
      <div className="row no-gutters ps-4">
        <div className="col-12">
          <div className={'row'}>
            <div className={'col-12 p-4'}>
              <QuickfiscoHeader title={lang.Title} menuItem={MenuItems.TAX_CALCULATION} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12 pb-4 pt-1 ps-4 pe-4'}>
              <TaxCalculationMainteinance />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (userState.status === 'loading' || taxProfileState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  if (taxProfileState.status === 'successfully') {
    return (
      <div className='row no-gutters ps-4'>
        <div className='col-12'>
          <div className={'row'}>
            <div className={'col-12 p-4'}>
              <QuickfiscoHeader title={lang.Title} menuItem={MenuItems.TAX_CALCULATION} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12 pb-4 ps-4 pe-4'}>
              <TaxForecast />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const user = userState.user;

  let component: React.ReactElement = <></>;

  if (user.status === 1 || user.status === 2 || user.status === 3 || !user.enabled) {
    component = (<TaxTrialUser />)
  } else if (!user.taxation || !user.category) {
    component = (<TaxIncompleteProfile />)
  } else if (taxProfileState.step === 'welcome') {
    component = (<TaxProfileWelcome />)
  } else if (taxProfileState.step === 'vatOpeningDate') {
    component = (<TaxProfileVatOpeningDate />)
  } else if (taxProfileState.step === 'confirmData') {
    component = (<TaxProfileConfirmData />)
  } else if (taxProfileState.step === 'currentYearData') {
    component = (<TaxProfileCurrentYearData />)
  } else if (taxProfileState.step === 'lastYearData') {
    component = (<TaxProfileLastYearData />)
  } else if (taxProfileState.step === 'fourthStep') {
    component = (<TaxProfileFourthStep />)
  } else if (taxProfileState.step === 'completed') {
    component = (<TaxProfileCompleted />)
  }


  return (
    <div className='row no-gutters ps-4'>
      <div className='col-12'>
        <div className={'row'}>
          <div className={'col-12 p-4'}>
            <QuickfiscoHeader title={lang.Title} menuItem={MenuItems.TAX_CALCULATION} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            <TaxProfileWizard>
              {component}
            </TaxProfileWizard>
          </div>
        </div>
      </div>
    </div>
  );
}
