import { SingleValue } from "react-select";
import { OptionModel } from "../../models/optionModel";
import { QuickfiscoSelect } from "../quickfiscoSelect/quickfiscoSelect";
import { CountryType } from "../../models/countryModel";

interface Props {
    id: string;
    value?: CountryType;
    error?: boolean;
    errorLabel?: string;
    onChange?: ((foreign: CountryType | undefined) => void);
    required?: boolean;
}

export function CountryTypeSelect(props: Props) {
    const { id } = props;
    const { value } = props;
    const { error } = props;
    const { errorLabel } = props;
    const { onChange } = props;
    const { required } = props;

    return (
        <QuickfiscoSelect
            id={id}
            value={getOptions(value).filter(option => option.selected === true)[0]}
            error={error}
            errorLabel={errorLabel}
            label={'Nazione'}
            styleType={'default'}
            requiredField={required}
            options={getOptions(value)}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(CountryType[e.value]);
                }
            }}
        />
    );
}

function getOptions(value?: string): OptionModel[] {
    return [
        { value: undefined, label: 'Seleziona Nazione', selected: value === undefined},
        { value: CountryType.AF, label: 'Afghanistan', selected: value === CountryType.AF },	
        { value: CountryType.AL, label: 'Albania', selected: value === CountryType.AL },	
        { value: CountryType.DZ, label: 'Algeria', selected: value === CountryType.DZ },	
        { value: CountryType.AD, label: 'Andorra', selected: value === CountryType.AD},		
        { value: CountryType.AO, label: 'Angola', selected: value === CountryType.AO },	
        { value: CountryType.AI, label: 'Anguilla', selected: value === CountryType.AI },	
        { value: CountryType.AQ, label: 'Antartide', selected: value === CountryType.AQ },	
        { value: CountryType.AG, label: 'Antigua e Barbuda', selected: value === CountryType.AG },	
        { value: CountryType.SA, label: 'Arabia Saudita', selected: value === CountryType.SA },	
        { value: CountryType.AR, label: 'Argentina', selected: value === CountryType.AR },	
        { value: CountryType.AM, label: 'Armenia', selected: value === CountryType.AM },	
        { value: CountryType.AW, label: 'Aruba', selected: value === CountryType.AW },	
        { value: CountryType.AU, label: 'Australia', selected: value === CountryType.AU },	
        { value: CountryType.AT, label: 'Austria', selected: value === CountryType.AT },	
        { value: CountryType.AZ, label: 'Azerbaijan', selected: value === CountryType.AZ },	
        { value: CountryType.BS, label: 'Bahamas', selected: value === CountryType.BS },	
        { value: CountryType.BH, label: 'Bahrain', selected: value === CountryType.BH },	
        { value: CountryType.BD, label: 'Bangladesh', selected: value === CountryType.BD },	
        { value: CountryType.BB, label: 'Barbados', selected: value === CountryType.BB },	
        { value: CountryType.BE, label: 'Belgio', selected: value === CountryType.BE },	
        { value: CountryType.BZ, label: 'Belize', selected: value === CountryType.BZ },	
        { value: CountryType.BJ, label: 'Benin', selected: value === CountryType.BJ },	
        { value: CountryType.BM, label: 'Bermuda', selected: value === CountryType.BM },	
        { value: CountryType.BT, label: 'Bhutan', selected: value === CountryType.BT },	
        { value: CountryType.BY, label: 'Bielorussia', selected: value === CountryType.BY },	
        { value: CountryType.BO, label: 'Bolivia', selected: value === CountryType.BO },	
        { value: CountryType.BA, label: 'Bosnia Erzegovina', selected: value === CountryType.BA },	
        { value: CountryType.BW, label: 'Botswana', selected: value === CountryType.BW },	
        { value: CountryType.BR, label: 'Brasile', selected: value === CountryType.BR },	
        { value: CountryType.BN, label: 'Brunei Darussalam', selected: value === CountryType.BN },	
        { value: CountryType.BG, label: 'Bulgaria', selected: value === CountryType.BG },	
        { value: CountryType.BF, label: 'Burkina Faso', selected: value === CountryType.BF },	
        { value: CountryType.BI, label: 'Burundi', selected: value === CountryType.BI },	
        { value: CountryType.KH, label: 'Cambogia', selected: value === CountryType.KH },	
        { value: CountryType.CM, label: 'Camerun', selected: value === CountryType.CM },	
        { value: CountryType.CA, label: 'Canada', selected: value === CountryType.CA },	
        { value: CountryType.CV, label: 'Capo Verde', selected: value === CountryType.CV },	
        { value: CountryType.TD, label: 'Ciad', selected: value === CountryType.TD },	
        { value: CountryType.CL, label: 'Cile', selected: value === CountryType.CL },	
        { value: CountryType.CN, label: 'Cina', selected: value === CountryType.CN },	
        { value: CountryType.CY, label: 'Cipro', selected: value === CountryType.CY },	
        { value: CountryType.VA, label: 'Citta del Vaticano', selected: value === CountryType.VA },	
        { value: CountryType.CO, label: 'Colombia', selected: value === CountryType.CO },	
        { value: CountryType.KM, label: 'Comore', selected: value === CountryType.KM },	
        { value: CountryType.KP, label: 'Corea del Nord', selected: value === CountryType.KP },	
        { value: CountryType.KR, label: 'Corea del Sud', selected: value === CountryType.KR },	
        { value: CountryType.CI, label: "Costa d'Avorio", selected: value === CountryType.CI },	
        { value: CountryType.CR, label: 'Costa Rica', selected: value === CountryType.CR },	
        { value: CountryType.HR, label: 'Croazia', selected: value === CountryType.HR },	
        { value: CountryType.CU, label: 'Cuba', selected: value === CountryType.CU },	
        { value: CountryType.CW, label: 'Curaçao', selected: value === CountryType.CW },	
        { value: CountryType.DK, label: 'Danimarca', selected: value === CountryType.DK },	
        { value: CountryType.DM, label: 'Dominica', selected: value === CountryType.DM },	
        { value: CountryType.EC, label: 'Ecuador', selected: value === CountryType.EC },	
        { value: CountryType.EG, label: 'Egitto', selected: value === CountryType.EG },	
        { value: CountryType.IE, label: 'Eire', selected: value === CountryType.IE },	
        { value: CountryType.SV, label: 'El Salvador', selected: value === CountryType.SV },	
        { value: CountryType.AE, label: 'Emirati Arabi Uniti', selected: value === CountryType.AE },	
        { value: CountryType.ER, label: 'Eritrea', selected: value === CountryType.ER },	
        { value: CountryType.EE, label: 'Estonia', selected: value === CountryType.EE },	
        { value: CountryType.ET, label: 'Etiopia', selected: value === CountryType.ET },	
        { value: CountryType.RU, label: 'Federazione Russa', selected: value === CountryType.RU },	
        { value: CountryType.FJ, label: 'Fiji', selected: value === CountryType.FJ },	
        { value: CountryType.PH, label: 'Filippine', selected: value === CountryType.PH },	
        { value: CountryType.FI, label: 'Finlandia', selected: value === CountryType.FI },	
        { value: CountryType.FR, label: 'Francia', selected: value === CountryType.FR },	
        { value: CountryType.GA, label: 'Gabon', selected: value === CountryType.GA },	
        { value: CountryType.GM, label: 'Gambia', selected: value === CountryType.GM },	
        { value: CountryType.GE, label: 'Georgia', selected: value === CountryType.GE },	
        { value: CountryType.DE, label: 'Germania', selected: value === CountryType.DE },	
        { value: CountryType.GH, label: 'Ghana', selected: value === CountryType.GH },	
        { value: CountryType.JM, label: 'Giamaica', selected: value === CountryType.JM },	
        { value: CountryType.JP, label: 'Giappone', selected: value === CountryType.JP },	
        { value: CountryType.GI, label: 'Gibilterra', selected: value === CountryType.GI },	
        { value: CountryType.DJ, label: 'Gibuti', selected: value === CountryType.DJ },	
        { value: CountryType.JO, label: 'Giordania', selected: value === CountryType.JO },	
        { value: CountryType.GR, label: 'Grecia', selected: value === CountryType.GR },	
        { value: CountryType.GD, label: 'Grenada', selected: value === CountryType.GD },	
        { value: CountryType.GL, label: 'Groenlandia', selected: value === CountryType.GL },	
        { value: CountryType.GP, label: 'Guadalupa', selected: value === CountryType.GP },	
        { value: CountryType.GU, label: 'Guam', selected: value === CountryType.GU },	
        { value: CountryType.GT, label: 'Guatemala', selected: value === CountryType.GT },	
        { value: CountryType.GG, label: 'Guernsey', selected: value === CountryType.GG },	
        { value: CountryType.GQ, label: 'Guinea Equatoriale', selected: value === CountryType.GQ },	
        { value: CountryType.GN, label: 'Guinea', selected: value === CountryType.GN },	
        { value: CountryType.GW, label: 'Guinea-Bissau', selected: value === CountryType.GW },	
        { value: CountryType.GF, label: 'Guyana Francese', selected: value === CountryType.GF },	
        { value: CountryType.GY, label: 'Guyana', selected: value === CountryType.GY },	
        { value: CountryType.HT, label: 'Haiti', selected: value === CountryType.HT },	
        { value: CountryType.HN, label: 'Honduras', selected: value === CountryType.HN },	
        { value: CountryType.HK, label: 'Hong Kong', selected: value === CountryType.HK },	
        { value: CountryType.IN, label: 'India', selected: value === CountryType.IN },	
        { value: CountryType.ID, label: 'Indonesia', selected: value === CountryType.ID },	
        { value: CountryType.IR, label: 'Iran', selected: value === CountryType.IR },	
        { value: CountryType.IQ, label: 'Iraq', selected: value === CountryType.IQ },	
        { value: CountryType.IS, label: 'Islanda', selected: value === CountryType.IS },	
        { value: CountryType.BV, label: 'Isola di Bouvet', selected: value === CountryType.BV },	
        { value: CountryType.JE, label: 'Isola di Jersey', selected: value === CountryType.JE },	
        { value: CountryType.IM, label: 'Isola di Man', selected: value === CountryType.IM },	
        { value: CountryType.CX, label: 'Isola di Natale', selected: value === CountryType.CX },	
        { value: CountryType.HM, label: 'Isola Heard e Isole McDonald', selected: value === CountryType.HM },	
        { value: CountryType.NF, label: 'Isola Norfolk', selected: value === CountryType.NF },	
        { value: CountryType.AX, label: 'Isole Åland', selected: value === CountryType.AX },	
        { value: CountryType.KY, label: 'Isole Cayman', selected: value === CountryType.KY },	
        { value: CountryType.CC, label: 'Isole Cocos', selected: value === CountryType.CC },	
        { value: CountryType.CK, label: 'Isole Cook', selected: value === CountryType.CK },	
        { value: CountryType.FK, label: 'Isole Falkland', selected: value === CountryType.FK },	
        { value: CountryType.FO, label: 'Isole Faroe', selected: value === CountryType.FO },	
        { value: CountryType.MP, label: 'Isole Marianne Settentrionali', selected: value === CountryType.MP },	
        { value: CountryType.MH, label: 'Isole Marshall', selected: value === CountryType.MH },	
        { value: CountryType.UM, label: "Isole Minori degli Stati Uniti d'America", selected: value === CountryType.UM },	
        { value: CountryType.SB, label: 'Isole Solomon', selected: value === CountryType.SB },	
        { value: CountryType.TC, label: 'Isole Turks e Caicos', selected: value === CountryType.TC },	
        { value: CountryType.VI, label: 'Isole Vergini Americane', selected: value === CountryType.VI },	
        { value: CountryType.VG, label: 'Isole Vergini Britanniche', selected: value === CountryType.VG },	
        { value: CountryType.IL, label: 'Israele', selected: value === CountryType.IL },	
        { value: CountryType.IT, label: 'Italia', selected: value === CountryType.IT },	
        { value: CountryType.KZ, label: 'Kazakhistan', selected: value === CountryType.KZ },	
        { value: CountryType.KE, label: 'Kenya', selected: value === CountryType.KE },	
        { value: CountryType.KG, label: 'Kirghizistan', selected: value === CountryType.KG },	
        { value: CountryType.KI, label: 'Kiribati', selected: value === CountryType.KI },	
        { value: CountryType.KW, label: 'Kuwait', selected: value === CountryType.KW },	
        { value: CountryType.LA, label: 'Laos', selected: value === CountryType.LA },	
        { value: CountryType.LS, label: 'Lesotho', selected: value === CountryType.LS },	
        { value: CountryType.LV, label: 'Lettonia', selected: value === CountryType.LV },	
        { value: CountryType.LB, label: 'Libano', selected: value === CountryType.LB },	
        { value: CountryType.LR, label: 'Liberia', selected: value === CountryType.LR },	
        { value: CountryType.LY, label: 'Libia', selected: value === CountryType.LY },	
        { value: CountryType.LI, label: 'Liechtenstein', selected: value === CountryType.LI },	
        { value: CountryType.LT, label: 'Lituania', selected: value === CountryType.LT },	
        { value: CountryType.LU, label: 'Lussemburgo', selected: value === CountryType.LU },	
        { value: CountryType.MO, label: 'Macao', selected: value === CountryType.MO },	
        { value: CountryType.MK, label: 'Macedonia', selected: value === CountryType.MK },	
        { value: CountryType.MG, label: 'Madagascar', selected: value === CountryType.MG },	
        { value: CountryType.MW, label: 'Malawi', selected: value === CountryType.MW },	
        { value: CountryType.MV, label: 'Maldive', selected: value === CountryType.MV },	
        { value: CountryType.MY, label: 'Malesia', selected: value === CountryType.MY },	
        { value: CountryType.ML, label: 'Mali', selected: value === CountryType.ML },	
        { value: CountryType.MT, label: 'Malta', selected: value === CountryType.MT },	
        { value: CountryType.MA, label: 'Marocco', selected: value === CountryType.MA },	
        { value: CountryType.MQ, label: 'Martinica', selected: value === CountryType.MQ },	
        { value: CountryType.MR, label: 'Mauritania', selected: value === CountryType.MR },	
        { value: CountryType.MU, label: 'Maurizius', selected: value === CountryType.MU },	
        { value: CountryType.YT, label: 'Mayotte', selected: value === CountryType.YT },	
        { value: CountryType.MX, label: 'Messico', selected: value === CountryType.MX },	
        { value: CountryType.MD, label: 'Moldavia', selected: value === CountryType.MD },	
        { value: CountryType.MC, label: 'Monaco', selected: value === CountryType.MC },	
        { value: CountryType.MN, label: 'Mongolia', selected: value === CountryType.MN },	
        { value: CountryType.ME, label: 'Montenegro', selected: value === CountryType.ME },	
        { value: CountryType.MS, label: 'Montserrat', selected: value === CountryType.MS },	
        { value: CountryType.MZ, label: 'Mozambico', selected: value === CountryType.MZ },	
        { value: CountryType.MM, label: 'Myanmar', selected: value === CountryType.MM },	
        { value: CountryType.NA, label: 'Namibia', selected: value === CountryType.NA },	
        { value: CountryType.NR, label: 'Nauru', selected: value === CountryType.NR },	
        { value: CountryType.NP, label: 'Nepal', selected: value === CountryType.NP },	
        { value: CountryType.NI, label: 'Nicaragua', selected: value === CountryType.NI },	
        { value: CountryType.NE, label: 'Niger', selected: value === CountryType.NE },	
        { value: CountryType.NG, label: 'Nigeria', selected: value === CountryType.NG },	
        { value: CountryType.NU, label: 'Niue', selected: value === CountryType.NU },	
        { value: CountryType.NO, label: 'Norvegia', selected: value === CountryType.NO },	
        { value: CountryType.NC, label: 'Nuova Caledonia', selected: value === CountryType.NC },	
        { value: CountryType.NZ, label: 'Nuova Zelanda', selected: value === CountryType.NZ },	
        { value: CountryType.OM, label: 'Oman', selected: value === CountryType.OM },	
        { value: CountryType.BQ, label: 'Paesi Bassi caraibici', selected: value === CountryType.BQ },	
        { value: CountryType.NL, label: 'Paesi Bassi', selected: value === CountryType.NL },	
        { value: CountryType.PK, label: 'Pakistan', selected: value === CountryType.PK },	
        { value: CountryType.PW, label: 'Palau', selected: value === CountryType.PW },	
        { value: CountryType.PA, label: 'Panama', selected: value === CountryType.PA },	
        { value: CountryType.PG, label: 'Papua Nuova Guinea', selected: value === CountryType.PG },	
        { value: CountryType.PY, label: 'Paraguay', selected: value === CountryType.PY },	
        { value: CountryType.PE, label: 'Peru', selected: value === CountryType.PE },	
        { value: CountryType.PN, label: 'Pitcairn', selected: value === CountryType.PN },	
        { value: CountryType.PF, label: 'Polinesia Francese', selected: value === CountryType.PF },	
        { value: CountryType.PL, label: 'Polonia', selected: value === CountryType.PL },	
        { value: CountryType.PR, label: 'Porto Rico', selected: value === CountryType.PR },	
        { value: CountryType.PT, label: 'Portogallo', selected: value === CountryType.PT },	
        { value: CountryType.QA, label: 'Qatar', selected: value === CountryType.QA },	
        { value: CountryType.GB, label: 'Regno Unito', selected: value === CountryType.GB },	
        { value: CountryType.CZ, label: 'Repubblica Ceca', selected: value === CountryType.CZ },	
        { value: CountryType.CF, label: 'Repubblica Centroafricana', selected: value === CountryType.CF },	
        { value: CountryType.CG, label: 'Repubblica del Congo', selected: value === CountryType.CG },	
        { value: CountryType.CD, label: 'Repubblica Democratica del Congo', selected: value === CountryType.CD },	
        { value: CountryType.DO, label: 'Repubblica Dominicana', selected: value === CountryType.DO },	
        { value: CountryType.RE, label: 'Reunion', selected: value === CountryType.RE },	
        { value: CountryType.RO, label: 'Romania', selected: value === CountryType.RO },	
        { value: CountryType.RW, label: 'Ruanda', selected: value === CountryType.RW },	
        { value: CountryType.EH, label: 'Sahara Occidentale', selected: value === CountryType.EH },	
        { value: CountryType.KN, label: 'Saint Kitts e Nevis', selected: value === CountryType.KN },	
        { value: CountryType.PM, label: 'Saint Pierre e Miquelon', selected: value === CountryType.PM },	
        { value: CountryType.VC, label: 'Saint Vincent e Grenadine', selected: value === CountryType.VC },	
        { value: CountryType.BL, label: 'Saint-Barthélemy', selected: value === CountryType.BL },	
        { value: CountryType.MF, label: 'Saint-Martin', selected: value === CountryType.MF },	
        { value: CountryType.AS, label: 'Samoa Americane', selected: value === CountryType.AS },	
        { value: CountryType.WS, label: 'Samoa', selected: value === CountryType.WS },	
        { value: CountryType.SM, label: 'San Marino', selected: value === CountryType.SM },	
        { value: CountryType.LC, label: 'Santa Lucia', selected: value === CountryType.LC },	
        { value: CountryType.SH, label: "Sant'Elena", selected: value === CountryType.SH },	
        { value: CountryType.ST, label: 'Sao Tome e Principe', selected: value === CountryType.ST },	
        { value: CountryType.SN, label: 'Senegal', selected: value === CountryType.SN },	
        { value: CountryType.RS, label: 'Serbia', selected: value === CountryType.RS },	
        { value: CountryType.SC, label: 'Seychelles', selected: value === CountryType.SC },	
        { value: CountryType.SL, label: 'Sierra Leone', selected: value === CountryType.SL },	
        { value: CountryType.SG, label: 'Singapore', selected: value === CountryType.SG },	
        { value: CountryType.SX, label: 'Sint Maarten', selected: value === CountryType.SX },	
        { value: CountryType.SY, label: 'Siria', selected: value === CountryType.SY },	
        { value: CountryType.SK, label: 'Slovacchia', selected: value === CountryType.SK },	
        { value: CountryType.SI, label: 'Slovenia', selected: value === CountryType.SI },	
        { value: CountryType.SO, label: 'Somalia', selected: value === CountryType.SO },	
        { value: CountryType.ES, label: 'Spagna', selected: value === CountryType.ES },	
        { value: CountryType.LK, label: 'Sri Lanka', selected: value === CountryType.LK },	
        { value: CountryType.FM, label: 'Stati Federati della Micronesia', selected: value === CountryType.FM },	
        { value: CountryType.US, label: "Stati Uniti d'America", selected: value === CountryType.US },	
        { value: CountryType.ZA, label: 'Sud Africa', selected: value === CountryType.ZA },	
        { value: CountryType.GS, label: 'Sud Georgia e Isole Sandwich', selected: value === CountryType.GS },	
        { value: CountryType.SS, label: 'Sudan del Sud', selected: value === CountryType.SS },	
        { value: CountryType.SD, label: 'Sudan', selected: value === CountryType.SD },	
        { value: CountryType.SR, label: 'Suriname', selected: value === CountryType.SR },	
        { value: CountryType.SJ, label: 'Svalbard e Jan Mayen', selected: value === CountryType.SJ },	
        { value: CountryType.SE, label: 'Svezia', selected: value === CountryType.SE },	
        { value: CountryType.CH, label: 'Svizzera', selected: value === CountryType.CH },	
        { value: CountryType.SZ, label: 'Swaziland', selected: value === CountryType.SZ },	
        { value: CountryType.TJ, label: 'Tagikistan', selected: value === CountryType.TJ },	
        { value: CountryType.TH, label: 'Tailandia', selected: value === CountryType.TH },	
        { value: CountryType.TW, label: 'Taiwan', selected: value === CountryType.TW },	
        { value: CountryType.TZ, label: 'Tanzania', selected: value === CountryType.TZ },	
        { value: CountryType.IO, label: "Territori Britannici dell'Oceano Indiano", selected: value === CountryType.IO },	
        { value: CountryType.TF, label: 'Territori Francesi del Sud', selected: value === CountryType.TF },	
        { value: CountryType.PS, label: 'Territori Palestinesi Occupati', selected: value === CountryType.PS },	
        { value: CountryType.TL, label: 'Timor Est', selected: value === CountryType.TL },	
        { value: CountryType.TG, label: 'Togo', selected: value === CountryType.TG },	
        { value: CountryType.TK, label: 'Tokelau', selected: value === CountryType.TK },	
        { value: CountryType.TO, label: 'Tonga', selected: value === CountryType.TO },	
        { value: CountryType.TT, label: 'Trinidad e Tobago', selected: value === CountryType.TT },	
        { value: CountryType.TN, label: 'Tunisia', selected: value === CountryType.TN },	
        { value: CountryType.TR, label: 'Turchia', selected: value === CountryType.TR },	
        { value: CountryType.TM, label: 'Turkmenistan', selected: value === CountryType.TM },	
        { value: CountryType.TV, label: 'Tuvalu', selected: value === CountryType.TV },	
        { value: CountryType.UA, label: 'Ucraina', selected: value === CountryType.UA },	
        { value: CountryType.UG, label: 'Uganda', selected: value === CountryType.UG },	
        { value: CountryType.HU, label: 'Ungheria', selected: value === CountryType.HU },	
        { value: CountryType.UY, label: 'Uruguay', selected: value === CountryType.UY },	
        { value: CountryType.UZ, label: 'Uzbekistan', selected: value === CountryType.UZ },	
        { value: CountryType.VU, label: 'Vanuatu', selected: value === CountryType.VU },	
        { value: CountryType.VE, label: 'Venezuela', selected: value === CountryType.VE },	
        { value: CountryType.VN, label: 'Vietnam', selected: value === CountryType.VN },	
        { value: CountryType.WF, label: 'Wallis e Futuna', selected: value === CountryType.WF },	
        { value: CountryType.YE, label: 'Yemen', selected: value === CountryType.YE },	
        { value: CountryType.ZM, label: 'Zambia', selected: value === CountryType.ZM },	
        { value: CountryType.ZW, label: 'Zimbabwe', selected: value === CountryType.ZW	}	
    ];
}
