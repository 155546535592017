import { NavigateFunction, useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import useNewInvoice from '../../hooks/newInvoice';
import { ContactType } from '../../models/contactModel';
import { useAppSelector } from '../../redux/hooks';
import uri from '../../routers/quickfiscoUri.json';
import { InvoiceSign } from '../../types/strings';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';

interface Props {
  type: ContactType | InvoiceSign | 'documents' | 'messages';
}

export function EmptyList(props: Props) {
  const { type } = props;

  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.user);

  const user = useAppSelector(() => userState.user)

  let typeSupport: ContactType | InvoiceSign | 'documents' | 'messages' = type;
  if (type === 'customer' && user.stsEnabled) {
    typeSupport = 'sts';
  }

  const newInvoice = useNewInvoice(typeSupport as InvoiceSign);

  let title: string;
  let subtitle: string;
  let label: string;

  switch (typeSupport) {
    case ContactType.CUSTOMER:
      title = 'Nessun cliente salvato';
      subtitle =
        'Memorizza qui le anagrafiche dei tuoi clienti per emettere fatture ancor più velocemente.';
      label = 'NUOVO CLIENTE';
      break;
    case ContactType.SUPPLIER:
      title = 'Nessun fornitore salvato';
      subtitle =
        'Salva qui le anagrafiche dei tuoi fornitori per ritrovarle velocemente.';
      label = 'NUOVO FORNITORE';
      break;
    case 'customer':
      title = 'Nessuna fattura';
      subtitle = 'Emetti la tua prima fattura, è semplice con Quickfisco!';
      label = 'NUOVA FATTURA';
      break;
    case 'supplier':
      title = 'Nessuna fattura passiva';
      subtitle =
        'Per ricevere fatture su questa piattaforma comunica questo codice destinatario ' +
        config.SDI +
        ' al momento dell’acquisto o caricale direttamente da qui.';
      label = 'NUOVA FATTURA';
      break;
    case 'proforma':
      title = 'Nessuna proforma';
      subtitle =
        'Emetti la tua prima fattura proforma, è semplice con Quickfisco!';
      label = 'NUOVA PROFORMA';
      break;
    case 'occasionalJob':
      title = 'Nessuna prestazione occasionale';
      subtitle =
        'Emetti la tua prima ricevuta per prestazione occasionale, è semplice con Quickfisco!';
      label = 'NUOVA PRESTAZIONE OCCASIONALE';
      break;
    case 'creditSlip':
      title = 'Nessuna nota di credito';
      subtitle =
        'Emetti la tua prima nota di credito, è semplice con Quickfisco!';
      label = 'NUOVA NOTA DI CREDITO';
      break;
    case 'sts':
      title = 'Nessuna fattura';
      subtitle = 'Emetti la tua prima fattura, è semplice con Quickfisco!';
      label = 'NUOVA FATTURA';
      break;
    case 'auto':
      title = 'Nessuna autofattura';
      subtitle = 'Emetti la tua prima autofattura, è semplice con Quickfisco!';
      label = 'NUOVA AUTOFATTURA';
      break;
    case 'documents':
      title = 'Nessun Documento';
      subtitle = 'Non è presente alcun documento';
      label = '';
      break;
    case 'messages':
      title = 'Nessun Messaggio';
      subtitle = 'Non è presente alcun messaggio';
      label = '';
      break;
    default:
      return <div>Tipo contatto o fattura non valido</div>;
  }

  return (
    <div className={'d-flex flex-column align-items-center'}>
      <div className={'row title'}>{title}</div>
      <div className={'row p-grey mt-3'}>
        <div className={'col-8 offset-2 text-center'}>{subtitle}</div>
      </div>
      {label !== '' && (
        <div className={'row mt-3 mb-3'}>
          <QuickfiscoButton
            id={type + '-empty-list-button'}
            label={label}
            type={'primary'}
            onClick={() => goToNew(typeSupport, newInvoice, navigate)}
          />
        </div>
      )}
    </div>
  );
}

function goToNew(
  type: ContactType | InvoiceSign | 'documents' | 'messages',
  newInvoice: () => void,
  navigate: NavigateFunction
): void {
  if (type === ContactType.CUSTOMER) {
    navigate(uri.CustomerSave);
  } else if (type === ContactType.SUPPLIER) {
    navigate(uri.SupplierSave);
  } else {
    newInvoice();
  }
}
