import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import { CrmIntegrationService } from '../../services/crmIntegrationService';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { CategoryType } from '../../models/userModel';
import { MultiAtecoInput } from '../../components/multiAtecoInput/multiAtecoInput';
import { FundSelect } from '../../components/fundSelect/fundSelect';
import { QuickfiscoSwitchInput } from '../../components/quickfiscoSwitchInput/quickfiscoSwitchInput';
import { PromiseStatuses } from '../../types/strings';
import { useEffect, useState } from 'react';
import { UserRegistrationModel } from '../../models/onboardingModel';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { QuickfiscoDatePicker } from '../../components/quickfiscoDatePicker/quickfiscoDatePicker';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { getMe } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './fiscalInfoOnboarding.json';
import './fiscalInfoOnboarding.css';

export function FiscalInfoOnboarding() {
  const [atecos, setAtecos] = useState<string[]>([]);
  const [vatOpeningDate, setVatOpeningDate] = useState<Date | undefined>(
    undefined
  );
  const [vat, setVat] = useState<string>('');

  const [editStatus, setEditStatus] = useState<PromiseStatuses>('idle');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onboardingState = useAppSelector((state) => state.onboarding);
  const user = useAppSelector((state) => state.user.editUserRequest);
  const status = useAppSelector((state) => state.user.status);

  const crmIntegrationService = new CrmIntegrationService(dispatch);

  const categoryLabelMap = new Map<CategoryType | undefined, string>([
    [CategoryType.ARTISAN, 'Artigiano'],
    [
      CategoryType.FUND,
      'Libero Professionista con iscrizione Cassa Ordine Professionale',
    ],
    [
      CategoryType.NOT_FUND,
      'Libero Professionista con iscrizione Gestione Separata INPS',
    ],
    [CategoryType.TRADER, 'Commerciante'],
  ]);

  const dataToSend: UserRegistrationModel = {
    id: user.id,
    atecos,
    vat,
    vatOpeningDate,
    cassaPrevidenziale: user.fund,
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    setAtecos(user.atecos === undefined ? [] : user.atecos);
    setVat(user.vat ? user.vat : '');
    setVatOpeningDate(
      user.vatOpeningDate ? new Date(user.vatOpeningDate) : undefined
    );
  }, [user]);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || editStatus === 'failed') {
    return (
      <OnboardingApiError
        statusFailed={status === 'failed' || editStatus === 'failed'}
      />
    );
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="fiscal-info-onboarding-main-title">
                02. <span>{lang.title}</span>
              </div>
              <div className="fiscal-info-onboarding-subtitle">
                {lang.subTitle1}
                <br />
                {lang.subTitle2}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-8 offset-2 fiscal-info-onboarding-container box-shadow pb-5 pt-3'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-10 offset-1'}>
                  <div className={'row no-gutters'}>
                    <div className={'col fiscal-info-onboarding-section-title'}>
                      {lang.fiscalData}
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-6">
                      <QuickfiscoInput
                        id="profile-fiscal-informations-regime"
                        label={lang.FiscalRegime}
                        styleType={'default'}
                        type="text"
                        required={true}
                        disabled={true}
                        placeholder={lang.valueNotPresent}
                        value={
                          user.fiscalRegime === 'flat'
                            ? lang.FiscalRegimeFlat
                            : lang.FiscalRegimeOrdinary
                        }
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="profile-fiscal-informations-previousVatStatus"
                        label={lang.previousVatStatus}
                        styleType={'default'}
                        type="text"
                        disabled={true}
                        required={true}
                        value={
                          user.previousVatStatus === 'new'
                            ? 'Nuova'
                            : user.previousVatStatus === 'surroga'
                            ? 'Surroga'
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <QuickfiscoInput
                        id="profile-fiscal-informations-category"
                        label={lang.Category}
                        styleType={'default'}
                        type="text"
                        disabled={true}
                        required={true}
                        value={categoryLabelMap.get(user.category)}
                        placeholder={lang.CategoryPlaceholder}
                      />
                    </div>
                  </div>
                  {user.previousVatStatus === 'surroga' && (
                    <div className="row no-gutters mt-3">
                      <div className="col-6">
                        <QuickfiscoInput
                          id="profile-fiscal-informations-vat"
                          label={lang.Vat}
                          styleType={'default'}
                          type="text"
                          required={true}
                          placeholder={lang.VatPlaceholder}
                          value={vat}
                          onBlur={(e) =>
                            crmIntegrationService.validateVat(e.target.value)
                          }
                          onChange={(e) =>
                            e.target.value && setVat(e.target.value)
                          }
                          error={onboardingState.errorVat}
                          errorLabel={lang.ErrorVat}
                        />
                      </div>
                      <div className="col-6">
                        <QuickfiscoDatePicker
                          id="personal-fiscal-onboarding-vatOpeningDate"
                          label={lang.VatOpeningDate}
                          styleType={'default'}
                          value={
                            vatOpeningDate
                              ? new Date(vatOpeningDate)
                              : undefined
                          }
                          maxDate={new Date()}
                          onChange={(e) =>
                            e &&
                            setVatOpeningDate(
                              resolveUndefinedDate(formatDateUS(e))
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="row no-gutters my-3">
                    <div className="col-6">
                      <QuickfiscoInput
                        id="profile-fiscal-informations-taxation"
                        label={lang.taxation}
                        styleType={'default'}
                        type="text"
                        disabled={true}
                        required={true}
                        value={user.taxation === 5 ? '5%' : '15%'}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters mb-4">
                    <div className="col-12">
                      <MultiAtecoInput
                        atecoList={atecos.length === 0 ? undefined : atecos}
                        error={onboardingState.errorAteco}
                        onChange={(_ateco) => setAtecos(_ateco)}
                        isReadOnly={user.previousVatStatus === 'new'}
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters'}>
                    <div className={'col fiscal-info-onboarding-section-title'}>
                      {lang.contributionData}
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <FundSelect
                        id={'profile-fiscal-informations-fund'}
                        required={true}
                        value={user.fund}
                        disabled={true}
                        visibleNumber={true}
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-4 d-flex justify-content-center align-items-center">
                      <QuickfiscoButton
                        type={'primary'}
                        id="fiscal-info-onboarding-go-back"
                        label={lang.goBack}
                        onClick={() => navigate(uri.PersonalInfo)}
                      />
                    </div>
                    <div className="col-4 offset-4 d-flex justify-content-center align-items-center">
                      {editStatus === 'loading' ? (
                        <QuickfiscoSpinner />
                      ) : (
                        <QuickfiscoButton
                          type={'secondary'}
                          id="fiscal-info-onboarding-edit"
                          label={lang.goNext}
                          onClick={() =>
                            update(
                              dataToSend,
                              dispatch,
                              navigate,
                              setEditStatus,
                              user.previousVatStatus === 'surroga'
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function update(
  user: UserRegistrationModel,
  dispatch: Function,
  navigate: NavigateFunction,
  setStatus: SetState<PromiseStatuses>,
  skipVat: boolean
) {
  if (user.id) {
    const crmIntegrationService = new CrmIntegrationService(dispatch);

    if (!(await crmIntegrationService.validateFiscallInfo(user, skipVat))) {
      return;
    }

    const userService = new UserService();

    setStatus('loading');

    userService
      .onboardingUpdateUser({ ...user, registrationStep: 'profile_fiscal' })
      .then(() => {
        navigate(uri.Documents);
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }
}
