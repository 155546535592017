import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import { CrmIntegrationService } from '../../services/crmIntegrationService';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../../components/quickfiscoDatePicker/quickfiscoDatePicker';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { DocIdTypeSelect } from '../../components/docIdTypeSelect/docIdTypeSelect';
import { DocModel } from '../../models/userModel';
import { useEffect, useState } from 'react';
import { UserRegistrationModel } from '../../models/onboardingModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { UploadDocId } from '../../components/uploadDocId/uploadDocId';
import { getMe } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './documentsOnboarding.json';
import './documentsOnboarding.css';

export function DocumentsOnboarding() {
  const [idDoc, setIdDoc] = useState<DocModel>({
    type: undefined,
    number: '',
    releasedBy: '',
  });
  const [taxDoc, setTaxDoc] = useState<DocModel>({
    objectId: undefined,
  });

  const [editStatus, setEditStatus] = useState<PromiseStatuses>('idle');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onboardingState = useAppSelector((state) => state.onboarding);
  const user = useAppSelector((state) => state.user.editUserRequest);
  const status = useAppSelector((state) => state.user.status);

  const crmIntegrationService = new CrmIntegrationService(dispatch);

  const dataToSend: UserRegistrationModel = {
    id: user.id,
    idDoc,
    taxDoc,
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    user.idDoc &&
      setIdDoc({
        objectId: user.idDoc.objectId,
        type: user.idDoc.type,
        number: user.idDoc.number,
        releasedBy: user.idDoc.releasedBy,
        releaseAt: user.idDoc.releaseAt
          ? new Date(user.idDoc.releaseAt)
          : undefined,
        expiredAt: user.idDoc.expiredAt
          ? new Date(user.idDoc.expiredAt)
          : undefined,
      });
    user.taxDoc &&
      setTaxDoc({
        objectId: user.taxDoc.objectId,
      });
  }, [user]);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || editStatus === 'failed') {
    return (
      <OnboardingApiError
        statusFailed={status === 'failed' || editStatus === 'failed'}
      />
    );
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="documents-onboarding-main-title">
                03. <span>{lang.title}</span>
              </div>
              <div className="documents-onboarding-subtitle">
                {lang.subTitle1}
                <br />
                {lang.subTitle2}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-8 offset-2 documents-onboarding-container box-shadow pb-5 pt-3'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-10 offset-1'}>
                  <div className={'row no-gutters'}>
                    <div className={'col documents-onboarding-section-title'}>
                      {lang.identityCard}
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-6">
                      <DocIdTypeSelect
                        id={'documents-onboarding-docIdType'}
                        onChange={(value) => {
                          value && setIdDoc({ ...idDoc, type: value });
                          value &&
                            crmIntegrationService.validateDocIdType(value);
                        }}
                        value={idDoc.type}
                        error={onboardingState.errorDocIdType}
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="documents-onboarding-docIdNumber"
                        label={lang.docIdNumber}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.docIdNumberPlaceholder}
                        onChange={(e) =>
                          e.target.value &&
                          setIdDoc({ ...idDoc, number: e.target.value })
                        }
                        value={idDoc.number || ''}
                        error={onboardingState.errorDocIdNumber}
                        errorLabel={lang.errorDocIdNumber}
                        required={true}
                        onBlur={(e) =>
                          crmIntegrationService.validateDocIdNumber(
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="documents-onboarding-docIdReleasedBy"
                        label={lang.docIdReleasedBy}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.docIdReleasedByPlaceholder}
                        onChange={(e) =>
                          e.target.value &&
                          setIdDoc({ ...idDoc, releasedBy: e.target.value })
                        }
                        value={idDoc.releasedBy || ''}
                        error={onboardingState.errorDocIdReleasedBy}
                        errorLabel={lang.errorDocIdReleasedBy}
                        required={true}
                        onBlur={(e) =>
                          crmIntegrationService.validateDocIdReleasedBy(
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-3">
                      <QuickfiscoDatePicker
                        id="documents-onboarding-docIdReleasedDate"
                        label={lang.docIdReleasedDate}
                        required={true}
                        styleType={'default'}
                        value={
                          idDoc.releaseAt
                            ? new Date(idDoc.releaseAt)
                            : undefined
                        }
                        maxDate={new Date()}
                        onChange={(e) =>
                          e &&
                          setIdDoc({
                            ...idDoc,
                            releaseAt: resolveUndefinedDate(formatDateUS(e)),
                          })
                        }
                        onBlur={(e) => {
                          crmIntegrationService.validateDocIdReleasedDate(
                            e.target.value
                          );
                        }}
                        error={onboardingState.errorDocIdReleasedDate}
                        errorLabel={lang.errorDocIdReleasedDate}
                      />
                    </div>
                    <div className="col-3">
                      <QuickfiscoDatePicker
                        id="documents-onboarding-docIdExpirationDate"
                        label={lang.docIdExpirationDate}
                        required={true}
                        styleType={'default'}
                        value={
                          idDoc.expiredAt
                            ? new Date(idDoc.expiredAt)
                            : undefined
                        }
                        futureDates={true}
                        onChange={(e) =>
                          e &&
                          setIdDoc({
                            ...idDoc,
                            expiredAt: resolveUndefinedDate(formatDateUS(e)),
                          })
                        }
                        onBlur={(e) => {
                          crmIntegrationService.validateDocIdExpirationDate(
                            e.target.value
                          );
                        }}
                        error={onboardingState.errorDocIdExpirationDate}
                        errorLabel={lang.errorDocIdExpirationDate}
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-6">
                      <UploadDocId
                        docType={'idDoc'}
                        docObj={idDoc}
                        dataToSend={dataToSend}
                      />
                    </div>
                    {onboardingState.errorIdDocFileId && (
                      <div className="col-6 documents-onboarding-errorDocFileId">
                        {lang.errorDocFileId}
                      </div>
                    )}
                    <div className="col-12 mt-2 documents-onboarding-upload-doc">
                      {lang.uploadDocRule}
                    </div>
                  </div>
                  <div className={'row no-gutters'}>
                    <div className={'col documents-onboarding-section-title'}>
                      {lang.healthCard}
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-6">
                      <UploadDocId
                        docType={'taxDoc'}
                        docObj={taxDoc}
                        dataToSend={dataToSend}
                      />
                    </div>
                    {onboardingState.errorTaxDocFileId && (
                      <div className="col-6 documents-onboarding-errorDocFileId">
                        {lang.errorDocFileId}
                      </div>
                    )}
                    <div className="col-12 mt-2 documents-onboarding-upload-doc">
                      {lang.uploadDocRule}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-4 d-flex justify-content-center align-items-center">
                      <QuickfiscoButton
                        type={'primary'}
                        id="fiscal-info-onboarding-go-back"
                        label={lang.goBack}
                        onClick={() => navigate(uri.FiscalInfo)}
                      />
                    </div>
                    <div className="col-4 offset-4 d-flex justify-content-center align-items-center">
                      {editStatus === 'loading' ? (
                        <QuickfiscoSpinner />
                      ) : (
                        <QuickfiscoButton
                          type={'secondary'}
                          id="fiscal-info-onboarding-edit"
                          label={lang.goNext}
                          onClick={() =>
                            update(
                              dataToSend,
                              dispatch,
                              navigate,
                              setEditStatus
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function update(
  user: UserRegistrationModel,
  dispatch: Function,
  navigate: NavigateFunction,
  setStatus: SetState<PromiseStatuses>
) {
  if (user.id) {
    const crmIntegrationService = new CrmIntegrationService(dispatch);

    if (!(await crmIntegrationService.validateDocuments(user))) {
      return;
    }

    const userService = new UserService();

    setStatus('loading');

    userService
      .onboardingUpdateUser({ ...user, registrationStep: 'your_documents' })
      .then(() => {
        navigate(uri.Contract);
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }
}
