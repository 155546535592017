import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import config from '../../../config.json';
import { SetState } from '../../types/functions';
import { downloadPdf } from '../../utils/file';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoLink } from '../quickfiscoLink/quickfiscoLink';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './quickfiscoInputFile.css';
import lang from './quickfiscoInputFile.json';

interface Props {
  maxHeight: string;
  preview?: boolean;
  multiple: boolean;
  fileList: File[];
  setFileList: ((fileList: File[]) => void);
  disabled?: boolean;
  error?: boolean;
  errorLabel?: string;
}

export function QuickfiscoInputFile(props: Props) {
  const { preview } = props;
  const { multiple } = props;
  const { maxHeight } = props;
  const { fileList } = props;
  const { setFileList } = props;
  const { disabled } = props;
  const { error } = props;
  const { errorLabel } = props;

  const [dragged, setDragged] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<any | null>(null);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  let fileListElement = getFileListElements(fileList, setFileList, disabled);

  useEffect(() => {
    doFilePreview(setFilePreview, fileList, preview);
  }, [fileList, preview]);

  return (
    <div>
      <div className={'mb-2'}>
        <span className={'quickfisco-input-file-title'}>{lang.Title}</span>
        <span className={'quickfisco-input-file-label'}>
          {lang.SizeRestrictionsLabel} {makeFileRestrictionsLabel()}
        </span>
      </div>
      {
        error && (
          <div className={'mb-2'}>
            <span className={'quickfisco-input-file-error'}>{errorLabel}</span>
          </div>
        )
      }
      {fileListElement.length > 0 && (
        <div className={'d-flex flex-wrap mb-2'}>
          {fileListElement}
        </div>
      )}
      <div
        className={'quickfisco-input-file ' + (dragged ? 'quickfisco-input-file-dragged' : '')}
        onDragOver={e => {
          e.preventDefault();
          e.stopPropagation();
          !disabled && setDragged(true);
        }}
        onDragEnter={e => {
          e.preventDefault();
          e.stopPropagation();
          !disabled && setDragged(true);
        }}
        onDragLeave={e => {
          e.preventDefault();
          e.stopPropagation();
          !disabled && setDragged(false);
        }}
        onDrop={e => {
          e.preventDefault();
          e.stopPropagation();
          !disabled && setDragged(false);
          !disabled && setFiles(
            e.dataTransfer.files,
            fileList,
            setFileList,
            multiple
          );
        }}
      >
        {preview && filePreview && (
          <div className="quickfisco-input-file-overflow" style={{ maxHeight: maxHeight }}>
            <Document
              file={filePreview}
              loading={() => {
                return (
                  <div className={'w-100 h-100 d-flex justify-content-center align-items-center'}>
                    <QuickfiscoSpinner />
                  </div>
                );
              }}
            >
              <Page pageNumber={1} />
            </Document>
          </div>
        )}
        <div className={'mt-3 mb-3 d-flex justify-content-center'}>
          <span className={'quickfisco-input-file-label me-1'}>{lang.Label1}</span>
          <QuickfiscoLink
            label={lang.Label2}
            id={'select-document'}
            onClick={() => inputRef?.click()}
          />
        </div>
        <input
          type="file"
          multiple={multiple}
          className={'d-none'}
          ref={ref => setInputRef(ref)}
          onChange={e => setFiles(
            e.target.files,
            fileList,
            setFileList,
            multiple
          )}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

function setFiles(
  files: FileList | null,
  previousFiles: File[] | null,
  setFileList: ((value: File[]) => void),
  multiple: boolean
): void {
  if (!files) {
    return;
  }

  if (!multiple) {
    setFileList([files.item(0) as File]);
    return;
  }

  const fileListTemp: File[] = [];

  for (let i = 0; i < files.length; i++) {
    const file = files.item(i) as File;

    fileListTemp.push(file);
  }

  if (fileListTemp.length > 0) {
    if (previousFiles !== null) {
      setFileList([...previousFiles, ...fileListTemp]);
    } else {
      setFileList(fileListTemp);
    }
  }
}

function getFileListElements(
  fileList: File[],
  setFileList: ((value: File[]) => void),
  disabled?: boolean
): React.ReactNode[] {
  let fileListElements: React.ReactNode[] = [];

  if (!fileList) {
    return fileListElements;
  }

  fileList.forEach((file, index) => {
    fileListElements.push(
      <div
        key={file.name + Math.random()}
        className={'quickfisco-input-file-name-container me-2 mt-1 mb-1 d-flex align-items-center'}
      >
        <span className={'quickfisco-input-file-name me-2'}>{file.name}</span>
        <QuickfiscoIcon
          name={'download.svg'}
          className={'quickfisco-input-download-file me-2'}
          isButton={true}
          onClick={() => {
            file.arrayBuffer().then((data) => getDownloadDocumentsName(file.name, data))
          }}
        />
        <QuickfiscoIcon
          name={'close-icon.svg'}
          className={'quickfisco-input-file-remove'}
          isButton={!disabled}
          onClick={() => {
            if (fileList && !disabled) {
              const newFileList = [...fileList];

              newFileList.splice(index, 1);

              setFileList([...newFileList]);
            }
          }}
        />
      </div>
    );
  });

  return fileListElements;
}

function doFilePreview(
  setFilePreview: SetState<any | null>,
  fileList: File[],
  preview?: boolean
): void {
  if (!preview) {
    return;
  }

  for (const file of fileList) {
    if (file.type === 'application/pdf') {
      file
        .arrayBuffer()
        .then(buff => setFilePreview({ data: new Uint8Array(buff) }));
      return;
    }
  }

  setFilePreview(undefined);
}

function makeFileRestrictionsLabel(): string {
  let label = '';

  for (let supportedType of config.supportedFileTypes) {
    label = label + supportedType + ', ';
  }

  return label.substring(0, label.length - 2) + ')';
}

function getDownloadDocumentsName(
  name?: string,
  data?: ArrayBuffer,
): void {
  if (!name || !data) {
    return
  }
  downloadPdf(name, data)
}