import { RightHeaderMenu } from '../rightHeaderMenu/rightHeaderMenu';
import { CreateHeaderDropdownMenu } from '../createHeaderDropdownMenu/createHeaderDropdownMenu';
import { MenuItems } from '../quickfiscoMenu/quickfiscoMenu';
import { TaxCalculationGuidePopup } from '../taxCalculationGuidePopup/taxCalculationGuidePopup';
import { useLocation } from 'react-router-dom';
import { CategoryType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import uri from '../../routers/quickfiscoUri.json';
import './quickfiscoHeader.css';

interface Props {
  title: string;
  menuItem?: MenuItems;
}

export function QuickfiscoHeader(props: Props) {
  const { title } = props;
  const { menuItem } = props;

  const userState = useAppSelector((state) => state.user);
  const user = userState.user;

  const location = useLocation();
  const showCreateHeaderDropdownMenuArray = [
    uri.CustomerInvoiceSave,
    uri.CreditSlipInvoiceSave,
    uri.StsInvoiceSave,
    uri.SupplierInvoiceSave,
    uri.ProformaInvoiceSave,
    uri.AutoInvoiceSave,
    uri.OccasionalJobInvoiceSaveOrDuplicate,
    uri.TaxCalculation,
  ];

  return (
    <div className="q-header-lowhead">
      <div className="q-header-lefthead d-flex align-items-center">
        <div>
          <div className="q-header-welcome">{title}</div>
        </div>
      </div>
      {menuItem === MenuItems.TAX_CALCULATION &&
        user?.category !== CategoryType.FUND && (
          <div className="row me-4 d-none d-xxl-flex d-xl-flex">
            <TaxCalculationGuidePopup />
          </div>
        )}
      <div className="d-flex">
        {!showCreateHeaderDropdownMenuArray.includes(location.pathname) &&
          user?.status !== 6 && <CreateHeaderDropdownMenu />}
        <RightHeaderMenu />
      </div>
    </div>
  );
}
