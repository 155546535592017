import { useProSidebar } from 'react-pro-sidebar';
import { NotSavedProfileModal } from '../../components/notSavedProfileModal/notSavedProfileModal';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import config from '../../../config.json';
import lang from './support.json';
import './support.css';

export function Support() {
  const { collapsed } = useProSidebar();

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="support-menu" menuItem={MenuItems.PROFILE} />
        </div>
        <div
          className="col no-gutters"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row no-gutters ps-4">
            <div className="col-12 p-4">
              <div className={'row'}>
                <div className={'col-12'}>
                  <QuickfiscoHeader title={lang.Title} />
                </div>
              </div>
              <div className="row mt-2 text-center">
                <div className={'col-xl-6 col-12 mt-3'}>
                  <div
                    className={
                      'support-view-box-container support-view-box-container-white box-shadow'
                    }
                  >
                    <div>
                      <QuickfiscoIcon name={'billing-problem.svg'} />
                      <h2 className={'support-view-box-title pt-5 pb-2'}>
                        {lang.TitleBox1}
                      </h2>
                      <p className={'support-view-box-text pb-3'}>
                        {lang.TextBox1}
                      </p>
                      <a
                        href={config.whatsappLink}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        <div
                          className={
                            'support-view-box-cta support-view-box-cta-wa p-2'
                          }
                        >
                          {lang.CtaBox1}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={'col-xl-6 col-12 mt-3'}>
                  <div
                    className={
                      'support-view-box-container support-view-box-container-white box-shadow'
                    }
                  >
                    <div>
                      <QuickfiscoIcon name={'fiscal-doubt.svg'} />
                      <h2 className={'support-view-box-title pt-5 pb-2'}>
                        {lang.TitleBox2}
                      </h2>
                      <p className={'support-view-box-text pb-3'}>
                        {lang.TextBox2}
                      </p>

                      <a
                        target={'_blank'}
                        href={`mailto:${config.mail}`}
                        rel={'noreferrer'}
                      >
                        <div
                          className={
                            'support-view-box-cta support-view-box-cta-mail p-2'
                          }
                        >
                          {lang.CtaBox2}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className={'col-xl-7 col-12 mt-3'}>
                  <div
                    className={
                      'support-view-box-container support-view-box-container-dark-blue box-shadow'
                    }
                  >
                    <div>
                      <div className={'d-flex align-items-center'}>
                        <div className={'pe-5'}>
                          <QuickfiscoIcon name={'tutorial.svg'} />
                        </div>
                        <div className={'text-start'}>
                          <h2 className={'support-view-box-title'}>
                            {lang.TitleBox3}
                          </h2>
                          <a
                            href={'https://quickfisco.it/tutorial/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <div
                              style={{ margin: '0' }}
                              className={
                                'support-view-box-cta support-view-box-cta-tutorial-review text-center p-2 my-4'
                              }
                            >
                              {lang.CtaBox3}
                            </div>
                          </a>
                          <p className={'support-view-box-text'}>
                            {lang.TextBox3_1}
                            <span style={{ fontWeight: '600' }}>
                              {lang.TextBox3_2}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-xl-5 col-12 mt-3'}>
                  <div className={'support-view-box-container box-shadow'}>
                    <div>
                      <QuickfiscoIcon
                        name={'review-star.svg'}
                        className={'w-75 mb-3'}
                      />
                      <h2 className={'support-view-box3-title pb-2'}>
                        {lang.TitleBox4}
                      </h2>
                      <a
                        href={'https://g.page/r/CdlAmIwn8YstEAg/review'}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        <div
                          className={
                            'support-view-box-cta support-view-box-cta-tutorial-review p-2'
                          }
                        >
                          {lang.CtaBox4}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotSavedProfileModal />
        </div>
      </div>
    </div>
  );
}
