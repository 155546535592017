import { useState } from 'react';
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { UserStatusType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { PaymentModal } from '../paymentModal/paymentModal';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import conf from '../../../config.json';
import lang from './profileSubscription.json';
import './profileSubscription.css';

export function ProfileSubscription() {
  let paymentExpiration;
  let daysLeft;

  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector((state) => state.user);

  if (!userState.user) {
    return <></>;
  }

  const user = userState.user;

  if (user.lastPayment) {
    paymentExpiration = addDays(
      new Date(user.lastPayment),
      user.status !== UserStatusType.ACTIVE &&
        user.status !== UserStatusType.PAYMENT_DUE
        ? conf.trialSubscriptionPeriod
        : conf.SubscrptionPeriod
    );
    daysLeft = differenceInCalendarDays(paymentExpiration, new Date());
  }

  if (user.status === UserStatusType.PAYMENT_DUE) {
    daysLeft = 0;
  }

  let typeLabel;
  let labelColor;
  let services: React.ReactNode[];

  switch (user.status) {
    case UserStatusType.ACTIVE:
      typeLabel = lang.active;
      labelColor = 'profile-subscription-blue';
      break;
    case UserStatusType.PAYMENT_DUE:
      typeLabel = lang.expired;
      labelColor = 'profile-subscription-red';
      break;
    default:
      typeLabel = lang.trial;
      labelColor = 'profile-subscription-yellow';
      break;
  }

  services = lang.subscriptionService.map((value, index) => {
    return (
      <div className={'col-12 no-gutters'} key={index}>
        <div className="row mt-1 align-items-center">
          <div className={'col-auto pe-0 profile-subscription-row-service'}>
            <QuickfiscoIcon
              name={
                index > 0 && user.status !== 4
                  ? 'alert-error.svg'
                  : 'alert-success.svg'
              }
              className={'profile-subscription-row-service-icon'}
            />
          </div>
          <div className={'col-11 ps-0 profile-subscription-row-service'}>
            {value}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="profile-subscription-container box-shadow">
      <QuickfiscoError
        message={lang.sendError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        message={lang.SaveSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
        noClose={true}
      />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 profile-subscription-container-title">
              {lang.containerTitle}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-xxl-6 mt-3 d-inline-grid">
          <div className="row">
            <div className="col-12 profile-subscription-title mb-3">
              {lang.subscriptionStatusTitle}
            </div>
          </div>
          <div className="row no-gutters justify-content-start">
            <div className="col-8 col-xl-9 col-xxl-10 no-gutters">
              <div className="text-center py-5 px-3 profile-subscription-status-container">
                <div className="row">
                  <div className="col-5 d-flex justify-content-center">
                    <div
                      className={
                        'profile-subscription-status my-auto ' + labelColor
                      }
                    >
                      {typeLabel}
                    </div>
                  </div>
                  <div className="col-7 d-flex flex-column justify-content-center">
                    <div className="profile-subscription-p-black">
                      {lang.expiration}{' '}
                      {paymentExpiration &&
                        format(paymentExpiration, 'dd/MM/yyyy')}
                    </div>
                    {(daysLeft ?? NaN) >= 0 && (
                      <div
                        className={
                          'profile-subscription-days mt-1 ' + labelColor
                        }
                      >
                        {daysLeft}{' '}
                        {daysLeft === 1 ? lang.lastDay : lang.daysLeft}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="row no-gutters d-flex justify-content-center mt-4">
                  <div className="col-10">
                    {user.status === 3 || user.status === 4 ? (
                      <QuickfiscoButton
                        id={'invoice-auto-save-button'}
                        label={lang.renewButton}
                        onClick={() => setPaymentModalIsOpen(true)}
                        type={
                          (daysLeft ?? NaN) >= -7 && (daysLeft ?? NaN) <= 0
                            ? 'secondary'
                            : 'disabled'
                        }
                      />
                    ) : (
                      <>
                        {status === 'loading' ? (
                          <div
                            className={
                              'd-flex justify-content-center align-items-center w-100'
                            }
                          >
                            <QuickfiscoSpinner />
                          </div>
                        ) : (
                          <QuickfiscoButton
                            id={'invoice-auto-save-button'}
                            label={lang.activationButton}
                            onClick={() => activateSubscription(setStatus)}
                            type={
                              getItemWithExipiration(
                                'stopTrialSubscriptionActivationModal'
                              ) === 'true'
                                ? 'disabled'
                                : 'secondary'
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xxl-6 mt-3">
          <div className="profile-subscription-title mb-3">
            {lang.subscriptionServiceTitle}
          </div>
          <div className="row">
            <div className="col-12">{services}</div>
          </div>
        </div>
      </div>
      {/* <PaymentModal open={paymentModalIsOpen} setOpen={setPaymentModalIsOpen} /> */}
    </div>
  );
}

// function activateSubscription(setStatus: SetState<PromiseStatuses>) {
//   const service = new UserService();

//   setStatus('loading');
//   service
//     .sendActivateSubscriptionEmail()
//     .then(() => {
//       setStatus('successfully');
//       setItemWithExipiration('stopTrialSubscriptionActivationModal', {
//         value: 'true',
//         expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
//       });
//     })
//     .catch((err) => {
//       console.error(err);
//       setStatus('failed');
//     });
// }
