import { DocumentsModel } from '../../models/documentsModel';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { DocumentsStatusFiltersType } from '../../models/documentsFilters';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { formatDateIT } from '../../utils/date';
import { ObjectService } from '../../services/objectService';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDocumentsList } from '../../redux/slices/documentsListSlice';
import lang from './documentsModal.json';
import './documentsModal.css';

interface Props {
  open: boolean;
  document: DocumentsModel;
  openModal: (isOpen: boolean) => void;
  downloadPdf: () => void;
  downloadPdfStatus: PromiseStatuses;
  setDownloadPdfStatus: (status: PromiseStatuses) => void;
}

export function DocumentsModal(props: Props) {
  const { open } = props;
  const { openModal } = props;
  const { document } = props;
  const { downloadPdf } = props;
  const { downloadPdfStatus } = props;
  const { setDownloadPdfStatus } = props;

  const dispatch = useAppDispatch();

  const documentsListState = useAppSelector(state => state.documentsList)

  let documentType: string;
  switch (document.type) {
    case DocumentsStatusFiltersType.F24:
      documentType = lang.f24;
      break;
    case DocumentsStatusFiltersType.DECLARATION:
      documentType = lang.declaration;
      break;
    case DocumentsStatusFiltersType.OTHERS:
      documentType = lang.others;
      break;
    case DocumentsStatusFiltersType.TAX_IDENTITY:
      documentType = lang.taxIdentity;
      break;
    default:
      return <div>Tipo di contatto non valido.</div>;
  }

  return (
    <div className={'documents-modal-container'}>
      <QuickfiscoModal
        isOpen={open}
        hide={() => {
          openModal(false);
          setDownloadPdfStatus('idle');
          if (document.unread) {
            dispatch(getDocumentsList({
              page: documentsListState.page,
              type: documentsListState.statusFilter
            }))
          }
        }}
      >
        <div className={'p-2'}>
          <div className={'row'}>
            <div className={'col-10 offset-1 text-center'}>
              <div
                className={
                  'documents-modal-title d-flex align-items-center justify-content-center'
                }
              >
                <div>{document.object.name}</div>
                <div>
                  {document.important && (
                    <QuickfiscoIcon name={'warning.svg'} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-5 offset-1 text-left border-gray'}>
              <div className={'documents-modal-document-type my-3'}>
                {lang.type} <span>{documentType}</span>
              </div>
            </div>
            <div className={'col-5 border-gray'}>
              <div className={'documents-modal-date my-3 text-end'}>
                {lang.creationDate}{' '}
                <span>{formatDateIT(document.creationDate)}</span>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-10 offset-1 text-center border-gray'}>
              <div className="documents-modal-render-file d-flex justify-content-center align-items-center my-4">
                {document.object.id ? (
                  <Document
                    file={getFileUrl(document.object.id)}
                    loading={() => {
                      return (
                        <div
                          className={
                            'w-100 h-100 d-flex justify-content-center align-items-center'
                          }
                        >
                          <QuickfiscoSpinner />
                        </div>
                      );
                    }}
                    error={lang.previewPdfError}
                  >
                    <Page pageNumber={1} />
                  </Document>
                ) : (
                  <div>{lang.previewPdfError}</div>
                )}
              </div>
            </div>
          </div>
          {document.notes &&
            <div className={'row'}>
              <div className={'col-10 offset-1 text-left border-gray'}>
                <div className="documents-modal-notes-title mt-3">
                  {lang.notes}
                </div>
                <div className="documents-modal-notes-text">
                  {document.notes}
                </div>
              </div>
            </div>
          }
          <div className={'row mt-5'}>
            <div className={'col-4 offset-4 text-left'}>
              {downloadPdfStatus === 'loading' ? (
                <div
                  className={
                    'w-100 d-flex justify-content-center align-items-center'
                  }
                >
                  <QuickfiscoSpinner alignCenter={true} />
                </div>
              ) : downloadPdfStatus === 'idle' ||
                downloadPdfStatus === 'successfully' ? (
                <QuickfiscoButton
                  id={'documents-modal-download-pdf'}
                  label={lang.downloadPdf}
                  type={'primary'}
                  onClick={() => {
                    downloadPdf();
                  }}
                />
              ) : (
                <p>{lang.downloadPdfError}</p>
              )}
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}

function getFileUrl(documentId: string): string {
  const service = new ObjectService();
  return service.getFileUrl(documentId);
}
