import { useEffect, useState } from 'react';
import config from '../../../config.json';
import { ContactType } from '../../models/contactModel';
import { InvoiceModel, InvoiceType } from '../../models/invoiceModel';
import { SupplierInvoiceErrorSetters } from '../../models/supplierInvoiceErrorSetters';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSupplierInvoiceSaveOrEditInvoiceId, setSupplierInvoiceSaveOrEditOperation, setSupplierInvoiceSaveOrEditSuccessfullySaved } from '../../redux/slices/supplierInvoiceSaveOrEditSlice';
import { setSupplierInvoiceAmount, setSupplierInvoiceDate, setSupplierInvoiceFileList, setSupplierInvoiceNotes, setSupplierInvoiceNumber, setSupplierInvoicePaymentExpiration, setSupplierInvoiceSupplier } from '../../redux/slices/supplierInvoiceSlice';
import { InvoiceSupplierService } from '../../services/supplierInvoiceService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { formatDateUS, resolveUndefinedDate, toDate } from '../../utils/date';
import { validateFiles } from '../../utils/file';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { ContactSelect } from '../contactSelect/contactSelect';
import { InvoicePaymentList } from '../invoicePaymentList/invoicePaymentList';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import './supplierInvoiceSave.css';
import lang from './supplierInvoiceSave.json';

export function SupplierInvoiceSave() {
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');

  // TODO remove
  const [errorSupplier, setErrorSupplier] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorPaymentExpiration, setErrorPaymentExpiration] = useState(false);
  const [errorFiles, setErrorFiles] = useState(false);

  const service = new InvoiceSupplierService(
    {
      setErrorSupplier,
      setErrorDate,
      setErrorAmount,
      setErrorPaymentExpiration
    }
  );
  // TODO remove

  const userState = useAppSelector(state => state.user);
  const supplierInvoiceState = useAppSelector(state => state.supplierInvoice);
  const supplicerInvoiceSaveOrEditState = useAppSelector(state => state.supplierInvoiceSaveOrEdit)

  const user = userState.user
  const invoice = supplierInvoiceState.invoice
  const fileList = supplierInvoiceState.fileList
  const operation = supplicerInvoiceSaveOrEditState.operation
  const successfullySaved = supplicerInvoiceSaveOrEditState.successfullySaved

  const [userIsNotEnabled, setUserIsNotEnabled] = useState(UserService.isNotEnabled(user))
  const [invoiceIsEditable, setInvoiceIsEditable] = useState(InvoiceSupplierService.isEditable(invoice))

  useEffect(() => {
    setUserIsNotEnabled(UserService.isNotEnabled(user))
  }, [user.enabled, user.status])

  useEffect(() => {
    setInvoiceIsEditable(InvoiceSupplierService.isEditable(invoice))
  }, [invoice.status])

  if (status === 'loading' || supplierInvoiceState.findByIdStatus === 'loading') {
    return (
      <div
        className={'d-flex justify-content-center align-items-center w-100 invoice-customer-saveOrEdit-loading'}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  let amount = '0';
  if (invoice.amount !== undefined && invoice.amount !== null) {
    amount = formatNumberIT(invoice.amount)
  }

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        message="Fattura modificata con successo"
        active={status === "successfully"}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        message="Fattura salvata con successo"
        active={successfullySaved}
        close={() => dispatch(setSupplierInvoiceSaveOrEditSuccessfullySaved(false))}
      />
      <QuickfiscoError
        active={status === 'failed'}
        message={lang.SavingError}
        close={() => setStatus('idle')}
      />
      <div className={'col-12 col-xl-6 mt-4'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <ContactSelect
              type={ContactType.SUPPLIER}
              selected={invoice.supplier}
              setContact={contact => {
                dispatch(setSupplierInvoiceSupplier(contact));
                service.validateSupplier(contact);
              }}
              error={errorSupplier}
              requiredField={true}
              disabled={!invoiceIsEditable}
            />
          </div>
        </div>
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 invoice-supplier-save-container p-4'}>
            <div className={'row'}>
              <div className={'col-8'}>
                <QuickfiscoDatePicker
                  id={'invoice-supplier-save-date'}
                  label={lang.label1}
                  onChange={e => {
                    e && dispatch(setSupplierInvoiceDate(resolveUndefinedDate(formatDateUS(e))));
                  }}
                  value={new Date(invoice.date)}
                  error={errorDate}
                  errorLabel={lang.error1}
                  required={true}
                  disabled={!invoiceIsEditable}
                  styleType={'default'}
                  onBlur={(e) => {
                    service.validateDate(resolveUndefinedDate(e.target.value));
                  }}
                />
              </div>
              <div className={'col-4'}>
                <QuickfiscoInput
                  id={'invoice-supplier-save-number'}
                  type={'text'}
                  styleType={'default'}
                  label={lang.label5} placeholder={lang.placeholder5}
                  onChange={e => {
                    dispatch(setSupplierInvoiceNumber(e.target.value));
                  }}
                  defaultValue={invoice.number}
                  disabled={!invoiceIsEditable}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <QuickfiscoInput
                  id={'invoice-supplier-save-amount'}
                  type={'text'}
                  label={lang.label2}
                  styleType={'default'}
                  placeholder={lang.placeholder2 + config.EUR}
                  onChange={e => {
                    dispatch(setSupplierInvoiceAmount(parseNumber(e.target.value)));
                  }}
                  defaultValue={amount}
                  error={errorAmount}
                  errorLabel={lang.error2}
                  disabled={!invoiceIsEditable || (invoice.payments !== null && invoice.payments !== undefined && invoice.payments.length > 0)}
                  required={true}
                  onBlur={(e) => service.validateAmount(parseNumber(e.target.value))}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <QuickfiscoDatePicker
                  id={'invoice-supplier-save-payment-expiration'}
                  label={lang.label3}
                  onChange={e => {
                    e && dispatch(setSupplierInvoicePaymentExpiration(toDate(formatDateUS(e))));
                  }}
                  error={errorPaymentExpiration}
                  errorLabel={lang.error3}
                  value={invoice.paymentExpiration ? new Date(invoice.paymentExpiration) : undefined}
                  onBlur={(e) => {
                    service.validatePaymentExpiration(invoice.date, toDate(e.target.value));
                  }}
                  required={true}
                  disabled={!invoiceIsEditable}
                  styleType={'default'}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <QuickfiscoTextarea
                  id={'invoice-supplier-save-note'}
                  label={lang.label4}
                  disabled={!invoiceIsEditable}
                  placeholder={lang.placeholder4}
                  onChange={e => {
                    dispatch(setSupplierInvoiceNotes(e.target.value));
                  }}
                  value={invoice.notes === null ? undefined : invoice.notes}
                />
              </div>
            </div>
            {
              operation === 'edit' &&
              invoice.type === InvoiceType.TD01 &&
              (
                <div className={'row mt-4'}>
                  <div className={'col-12'}>
                    <InvoicePaymentList
                      invoiceSign={'supplier'}
                      invoice={invoice}
                      user={user}
                    />
                  </div>
                </div>
              )
            }
            <div className={'row mt-4'}>
              <div className={'col-5'}>
                <QuickfiscoButton
                  id={'invoice-supplier-save-saving'}
                  label={lang.labelButton1}
                  onClick={() => saveOrEdit(
                    invoice,
                    setStatus,
                    {
                      setErrorSupplier,
                      setErrorDate,
                      setErrorAmount,
                      setErrorPaymentExpiration
                    },
                    dispatch,
                    operation,
                    fileList,
                    setErrorFiles
                  )}
                  type={(userIsNotEnabled || !invoiceIsEditable) ? 'disabled' : 'secondary'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'col-12 col-xl-6 mt-4'}>
        <div className={'invoice-supplier-save-container p-4'}>
          <div className={'row'}>
            <div className={'col-12 invoice-supplier-save-input-file'}>
              <QuickfiscoInputFile
                maxHeight={'430px'}
                fileList={fileList}
                setFileList={_fileList => {
                  dispatch(setSupplierInvoiceFileList([
                    ..._fileList
                  ]));
                }}
                preview={true}
                multiple={false}
                error={errorFiles}
                errorLabel={lang.filesError}
                disabled={!invoiceIsEditable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function saveOrEdit(
  invoice: InvoiceModel,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: SupplierInvoiceErrorSetters,
  dispatch: Function,
  operation: Operation,
  fileList: File[],
  setErrorFiles: SetState<boolean>
): void {
  setErrorFiles(false);

  const service = new InvoiceSupplierService(errorSetters);

  if (!service.validate(invoice)) {
    return;
  }

  if (!validateFiles(fileList)) {
    setErrorFiles(true);
    return;
  }

  setStatus('loading');
  if (operation === 'save') {
    service
      .save({ ...invoice }, [...fileList], [])
      .then(id => {
        dispatch(setSupplierInvoiceSaveOrEditInvoiceId(id));
        dispatch(setSupplierInvoiceSaveOrEditOperation('edit'));
        dispatch(setSupplierInvoiceSaveOrEditSuccessfullySaved(true));
      })
      .catch(err => {
        setStatus('failed');
        console.error(err);
      });
  } else {
    service
      .edit({ ...invoice }, [...fileList], [])
      .then(() => setStatus('successfully'))
      .catch(err => {
        setStatus('failed');
        console.error(err);
      });
  }
}