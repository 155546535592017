import { useState } from 'react';
import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { DocumentsModel } from '../../models/documentsModel';
import { DocumentsStatusFiltersType } from '../../models/documentsFilters';
import { DocumentsMenu } from '../documentsMenu/documentsMenu';
import { DocumentsModal } from '../documentsModal/documentsModal';
import { PromiseStatuses } from '../../types/strings';
import { formatDateIT } from '../../utils/date';
import { ObjectService } from '../../services/objectService';
import { downloadPdf } from '../../utils/file';
import { DocumentsService } from '../../services/documentsService';
import { useAppDispatch } from '../../redux/hooks';
import { getUnreadCounters } from '../../redux/slices/messagesListSlice';
import lang from './documentsListRow.json';
import './documentsListRow.css';

interface Props {
  document: DocumentsModel;
}

export function DocumentsListRow(props: Props) {
  const { document } = props;

  const menuIsVisible = useComponentVisible(false);
  const [onMouseOver, setOnMouseOver] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [downloadPdfStatus, setDownloadPdfStatus] =
    useState<PromiseStatuses>('idle');

  const dispatch = useAppDispatch();

  let documentType: string;
  switch (document.type) {
    case DocumentsStatusFiltersType.F24:
      documentType = lang.f24;
      break;
    case DocumentsStatusFiltersType.DECLARATION:
      documentType = lang.declaration;
      break;
    case DocumentsStatusFiltersType.OTHERS:
      documentType = lang.others;
      break;
    case DocumentsStatusFiltersType.TAX_IDENTITY:
      documentType = lang.taxIdentity;
      break;
    default:
      return <div>Tipo di documento non valido.</div>;
  }

  const getDownloadPdf = async () => {
    if (document.object.id) {
      const service = new ObjectService();

      setDownloadPdfStatus('loading');
      service
        .findFile(document.object.id)
        .then((data) => {
          menuIsVisible.setIsVisible(false);
          setDownloadPdfStatus('idle');
          if (document.object.name)
            downloadPdf(document.object.name, data, document.object.type);
        })
        .catch((err) => {
          setDownloadPdfStatus('failed');
          console.error(err);
        });
    }
  };

  const markAsRead = async () => {
    if (document.id) {
      const service = new DocumentsService();

      setOpen(true);
      service
        .markAsRead(document.id)
        .then(() => {
          dispatch(getUnreadCounters());
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div
      className={`row no-gutters row-documents-container${
        document.unread ? '-unread' : ''
      }`}
    >
      <div className={'row no-gutters'}>
        <div className={'col-12'}>
          <div className={'documentsListRow-line'} />
        </div>
      </div>
      <div className={'row no-gutters documentsListRow-row align-items-center'}>
        <div
          className={'col no-gutters pb-3 pt-3'}
          onClick={() => {
            if (!document.unread) {
              setOpen(true)
            } else {
              markAsRead()
            }
          }}
        >
          <div className={'row no-gutters align-items-center'}>
            <div
              className={`col documentsListRow-document-type${
                document.unread ? '-unread' : ''
              }`}
            >
              {documentType}
            </div>
            <div
              className={'col documentsListRow-name d-flex align-items-center'}
            >
              <div>{document.object.name}</div>
              <div>
                {document.important && <QuickfiscoIcon name={'warning.svg'} />}
              </div>
            </div>
            <div className={'col documentsListRow-date documentsListRow-gray'}>
              {formatDateIT(document.creationDate)}
            </div>
            <div className={'col documentsListRow-note documentsListRow-gray'}>
              {document.notes}
            </div>
          </div>
        </div>
        <div
          className={
            'col-auto position-relative d-flex align-items-start justify-content-end'
          }
        >
          {menuIsVisible.isVisible ? (
            <div>
              <QuickfiscoIcon name={'menu-active.svg'} />
            </div>
          ) : (
            <div
              onMouseOver={() => setOnMouseOver(false)}
              onMouseOut={() => setOnMouseOver(false)}
              onClick={() => {
                menuIsVisible.setIsVisible(true);
                setOnMouseOver(false);
              }}
            >
              <QuickfiscoIcon
                name={onMouseOver ? 'menu-hover.svg' : 'menu.svg'}
              />
            </div>
          )}
          <div className={'documentsListRow-menu'} ref={menuIsVisible.ref}>
            <DocumentsMenu
              componentVisible={menuIsVisible}
              openModal={setOpen}
              unreadMessage={document.unread}
              markAsRead={markAsRead}
              downloadPdf={getDownloadPdf}
              downloadPdfStatus={downloadPdfStatus}
              setDownloadPdfStatus={setDownloadPdfStatus}
            />
          </div>
        </div>
      </div>
      <DocumentsModal
        open={open}
        document={document}
        openModal={setOpen}
        downloadPdf={getDownloadPdf}
        downloadPdfStatus={downloadPdfStatus}
        setDownloadPdfStatus={setDownloadPdfStatus}
      />
    </div>
  );
}
