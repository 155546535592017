import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { InvoiceReportList } from '../../components/invoiceReportList/invoiceReportList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { QuickfiscoSuccess } from '../../components/quickfiscoSuccess/quickfiscoSuccess';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomerInvoiceList, setCustomerInvoiceListIsStsFilter, setCustomerInvoiceListPage } from '../../redux/slices/customerInvoiceListSlice';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import './customerInvoiceList.css';
import lang from './customerInvoiceList.json';

export function CustomerInvoiceList() {
  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const operationStatusState = useAppSelector(state => state.operationStatus);

  const customerInvoices = customerInvoiceListState.invoices
  const globalStatus = operationStatusState.status

  const user = useAppSelector(state => state.user.user)

  const dispatch = useAppDispatch();
  const newInvoice = useNewInvoice(user.stsEnabled ? 'sts' : 'customer')

  const [isStsStatus, setIsStsStatus] = useState<boolean | undefined>(undefined);

  const { collapsed } = useProSidebar();


  useEffect(() => {
    if (customerInvoiceListState.page === undefined) {
      dispatch(setCustomerInvoiceListPage(0))
    }
  }, [])

  useEffect(() => {
    if (user.stsEnabled !== undefined && user.stsEnabled) {
      setIsStsStatus(true)
    } else if (user.stsEnabled !== undefined && !user.stsEnabled) {
      setIsStsStatus(false)
    }
    
    if (customerInvoiceListState.isStsFilter === undefined) {
      dispatch(setCustomerInvoiceListIsStsFilter(user.stsEnabled))
    }
  }, [user.stsEnabled])

  useEffect(() => {
    if (user) {
      dispatch(getCustomerInvoiceList({
        page: customerInvoiceListState.page,
        month: customerInvoiceListState.monthFilter,
        year: customerInvoiceListState.yearFilter,
        type: customerInvoiceListState.typeFilter,
        companyName: customerInvoiceListState.companyNameFilter,
        status: customerInvoiceListState.statusFilter,
        isSts: customerInvoiceListState.isStsFilter,
      }))
    }
  }, [
    customerInvoiceListState.page,
    customerInvoiceListState.monthFilter,
    customerInvoiceListState.yearFilter,
    customerInvoiceListState.typeFilter,
    customerInvoiceListState.companyNameFilter,
    customerInvoiceListState.statusFilter,
    customerInvoiceListState.isStsFilter,
    user.stsEnabled,
  ])

  useEffect(() => {
    if (customerInvoiceListState.page !== undefined && customerInvoiceListState.page > 0 && customerInvoiceListState.invoices.content.length === 0) {
      dispatch(setCustomerInvoiceListPage(customerInvoiceListState.page - 1))
    }
  }, [customerInvoiceListState.invoices.content])

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoSuccess
        message={lang.sendSuccess}
        active={globalStatus === 'successfully'}
        close={() =>
          dispatch(setGlobalStatus({
            status: 'idle'
          }))
        }
      />
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='customer-cutomer-invoice-list-menu'
            menuItem={MenuItems.CUSTOMER_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.title} />
              {user.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          {!isStsStatus &&
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <InvoiceReportList invoiceSign={'customer'} />
              </div>
            </div>
          }
          <div className={'row mt-4'}>
            <div className={'col-11'}>
              <InvoiceFilters invoiceSign={'customer'} isCustomerSts={isStsStatus} />
            </div>
            <div className={'col-1 d-flex justify-content-end'}>
              <QuickfiscoIcon
                name={'new.svg'}
                className={'w-f-35'}
                isButton={true}
                onClick={() => newInvoice()}
              />
            </div>
          </div>
          {user.stsEnabled &&
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <div className={'row no-gutters'}>
                  <div className={'col-12'}>
                    <div className={'row cutomer-invoice-list-buttons-container'}>
                      <div
                        className={`col-6 ${customerInvoiceListState.isStsFilter
                          ? 'cutomer-invoice-list-buttons-selected'
                          : 'cutomer-invoice-list-buttons'
                          }`}
                        onClick={() => {
                          dispatch(setCustomerInvoiceListIsStsFilter(true))
                          setIsStsStatus(true)
                        }}

                      >
                        <span>{lang.stsInvoicesButton}</span>
                      </div>
                      <div
                        className={`col-6 ${!customerInvoiceListState.isStsFilter
                          ? 'cutomer-invoice-list-buttons-selected'
                          : 'cutomer-invoice-list-buttons'
                          }`}
                        onClick={() => {
                          dispatch(setCustomerInvoiceListIsStsFilter(false))
                          setIsStsStatus(false)
                        }}
                      >
                        <span>{lang.customerInvoicesButton}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceList invoiceSign={'customer'} />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={customerInvoices.totalPages}
                  number={customerInvoices.number}
                  empty={customerInvoices.empty}
                  doPagination={(page: number) => dispatch(setCustomerInvoiceListPage(page))}
                  first={customerInvoices.first}
                  last={customerInvoices.last}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
