import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './stsProfileNotice.css';
import lang from './stsProfileNotice.json';

interface Props {
  isProforma?: boolean;
}

export function StsProfileNotice(props: Props) {
  const { isProforma } = props;

  return (
    <div className={'sts-profile-notice-container'}>
      <p className={'mb-0'}>
        <QuickfiscoIcon name={'alert-triangle-green.svg'} className={'pe-2'} />
        <b>{isProforma ? lang.boldProformaText : lang.boldText}</b>
        {!isProforma && lang.text}
      </p>
    </div>
  );
}
