import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setMarketing, updateMe } from '../../redux/slices/userSlice';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import usePrevious from '../../hooks/usePrevious';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { PromiseStatuses } from '../../types/strings';
import { UserModel } from '../../models/userModel';
import { ObjectService } from '../../services/objectService';
import { SetState } from '../../types/functions';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { downloadPdf } from '../../utils/file';
import { DocumentsPreviewModal } from '../documentsPreviewModal/documentsPreviewModal';
import config from '../../../config.json';
import './profileConsent.css';

export function ProfileConsent() {
  const [downloadStatus, setDownloadStatus] = useState<PromiseStatuses>('idle');
  const [open, setOpen] = useState<boolean>(false);

  const userState = useAppSelector((state) => state.user);
  const editUserRequest = userState.editUserRequest;
  const prevMarketing = usePrevious(userState.editUserRequest.marketing);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      userState.editUserRequest.marketing !== prevMarketing &&
      prevMarketing !== undefined
    ) {
      dispatch(updateMe(editUserRequest));
    }
  }, [userState.editUserRequest.marketing]);

  return (
    <div className="profile-consent-container box-shadow">
      <QuickfiscoError
        message={'Si è verificato un errore durante il download del contratto.'}
        active={downloadStatus === 'failed'}
        close={() => setDownloadStatus('idle')}
      />
      <div className="profile-consent-title">LEGAL</div>
      <div className="row mb-3">
        <div className="col-12 no-gutters">
          <div className="row no-gutters mt-3">
            <div className="col-12">
              <QuickfiscoCheckbox
                id="registerPrivacy"
                label={"Ho letto e acconsentito all'informativa sulla "}
                checked={userState.user.privacy}
                linkLabel={'privacy'}
                link={config.privacy}
                required={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12">
              <QuickfiscoCheckbox
                id="registerTerms"
                label={'Ho letto e acconsentito alle '}
                checked={userState.user.terms}
                linkLabel={"condizioni d'uso"}
                link={config.terms}
                required={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12">
              <QuickfiscoCheckbox
                id="registerMarketing"
                label={
                  'Ho letto e acconsento a essere aggiornato su sconti, promozioni e offerte a me dedicate descritte nell’informativa '
                }
                onChange={() =>
                  dispatch(setMarketing(!userState.editUserRequest.marketing))
                }
                checked={userState.editUserRequest.marketing}
                linkLabel={'marketing'}
                link={config.marketing}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="profile-consent-contract-container py-4 px-3">
            {!editUserRequest.contractDoc?.objectId ? (
              <>
                <div className="profile-consent-contract-title">
                  Il tuo contratto con Quickfisco
                </div>
                <div className="profile-consent-contract-text">
                  Caricheremo il contratto che hai firmato qui.
                </div>
              </>
            ) : (
              <div className="row no-gutters align-items-center justify-content-between">
                <div className="col d-flex profile-consent-contract-title">
                  Contratto.pdf
                </div>
                <div className="col-2 d-flex justify-content-center">
                  {downloadStatus === 'loading' ? (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center w-100'
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <div
                      className={'user-doc-download-document'}
                      onClick={() =>
                        getDownloadDoc(setDownloadStatus, editUserRequest)
                      }
                    >
                      <QuickfiscoIcon name={'download-negative.svg'} />
                    </div>
                  )}
                </div>
                <div className="col-2 d-flex justify-content-center">
                  <div
                    className={'user-doc-download-document'}
                    onClick={() => setOpen(true)}
                  >
                    <QuickfiscoIcon name={'find-negative.svg'} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DocumentsPreviewModal
        open={open}
        setOpen={setOpen}
        docId={editUserRequest.contractDoc?.objectId}
      />
    </div>
  );
}

function getDownloadDoc(
  setStatus: SetState<PromiseStatuses>,
  user?: UserModel
): void {
  if (user?.contractDoc) {
    const service = new ObjectService();
    setStatus('loading');

    const docId = user.contractDoc.objectId;

    if (docId) {
      service
        .findById(docId)
        .then((data) => {
          const objFile = data;

          if (objFile.id) {
            service
              .findFile(objFile.id)
              .then((data) => {
                setStatus('idle');
                if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
              })
              .catch((err) => {
                setStatus('failed');
                console.error(err);
              });
          }
        })
        .catch((err) => {
          setStatus('failed');
          console.error(err);
        });
    }
  }
}
