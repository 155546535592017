import lang from './extraPaidServices.json';
import './extraPaidServices.css';

export function ExtraPaidServices() {
  let vatServices: React.ReactNode[];
  let privateServices: React.ReactNode[];
  let cafServices: React.ReactNode[];

  vatServices = lang.vatServices.map((value, index) => {
    return (
      <div className={'col-12 no-gutters'} key={value + index}>
        <div className="row extra-paid-services-row no-gutters w-100 mt-3 align-items-center">
          <div className={'col-6 no-gutters extra-paid-services-row-service'}>
            {value}
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            {lang.vatPricing[index]}
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            {lang.vatPricingRights[index]}
          </div>
        </div>
      </div>
    );
  });

  privateServices = lang.privateServices.map((value, index) => {
    return (
      <div className={'col-12 no-gutters'} key={value + index}>
        <div className="row no-gutters extra-paid-services-row w-100 mt-3 align-items-center">
          <div className={'col-9 no-gutters extra-paid-services-row-service'}>
            {value}
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            {lang.privatePricing[index]}
          </div>
        </div>
      </div>
    );
  });

  cafServices = lang.cafServices.map((value, index) => {
    return (
      <div className={'col-12 no-gutters'} key={value + index}>
        <div className="row no-gutters extra-paid-services-row w-100 mt-3 align-items-center">
          <div className={'col-9 no-gutters extra-paid-services-row-service'}>
            {value}
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            {lang.cafPricing[index]}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="extra-paid-services-container box-shadow">
      <div className="extra-paid-services-title mb-4">{lang.title}</div>
      <div className={'extra-paid-services-list-container'}>
        <div className="row no-gutters">
          <div className={'col-6 no-gutters text-start'}>
            <b>{lang.vatServicesTitle}</b>
          </div>
          <div className={'col-3 no-gutters'}>
            <b>{lang.priceRow}</b>
          </div>
          <div className={'col-3 no-gutters'}>
            <b>{lang.vatPricingRightsRow}</b>
          </div>
        </div>
        <div className="row no-gutters mb-2">{vatServices}</div>
        <div className="row no-gutters pt-5">
          <div className={'col-9 no-gutters text-start'}>
            <b>{lang.privateServicesTitle}</b>
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            <b>{lang.priceRow}</b>
          </div>
        </div>
        <div className="row no-gutters mb-2">{privateServices}</div>
        <div className="row no-gutters pt-5">
          <div className={'col-9 no-gutters text-start'}>
            <b>{lang.cafServicesTitle}</b>
          </div>
          <div className={'col-3 no-gutters extra-paid-services-row-pricing'}>
            <b>{lang.priceRow}</b>
          </div>
        </div>
        <div className="row no-gutters mb-2">{cafServices}</div>
      </div>
    </div>
  );
}
