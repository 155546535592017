import { useEffect, useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { DocModel, UserModel } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ObjectService } from '../../services/objectService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { downloadPdf, validateFile } from '../../utils/file';
import { QuickfiscoInputDoc } from '../quickfiscoInputDoc/quickfiscoInputDoc';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { getMe } from '../../redux/slices/userSlice';
import { UserRegistrationModel } from '../../models/onboardingModel';
import { DocumentsPreviewModal } from '../documentsPreviewModal/documentsPreviewModal';
import { sanitizeString } from '../../utils/string';
import lang from './uploadDocId.json';
import './uploadDocId.css';

interface Props {
  docType: 'idDoc' | 'taxDoc';
  docObj: DocModel;
  dataToSend: UserRegistrationModel;
}

export function UploadDocId(props: Props) {
  const { docType } = props;
  const { docObj } = props;
  const { dataToSend } = props;

  const [error, setError] = useState(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [downloadStatus, setDownloadStatus] = useState<PromiseStatuses>('idle');
  const [docIsPresent, setDocIsPresent] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.editUserRequest);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setDocIsPresent(docObj.objectId && docObj.objectId !== '' ? true : false);
  }, [docObj]);

  if (status === 'loading') {
    return (
      <div className="upload-doc-id-box w-100 d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters align-items-center align-items-center upload-doc-id-box">
      <QuickfiscoSuccess
        message={lang.success}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <div className="col d-flex upload-doc-id-title">
        {!docIsPresent
          ? lang.loadingDoc
          : docType === 'idDoc'
          ? lang.docTypeIdDoc
          : lang.docTypeTaxDoc}
      </div>
      {docIsPresent && (
        <>
          <div className="col-2 d-flex justify-content-center">
            {downloadStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div
                className={'upload-doc-id-download-document'}
                onClick={() => getDownloadDoc(setDownloadStatus, docObj)}
              >
                <QuickfiscoIcon name={'download-negative.svg'} />
              </div>
            )}
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className={'upload-doc-id-download-document'}
              onClick={() => setOpen(true)}
            >
              <QuickfiscoIcon name={'find-negative.svg'} />
            </div>
          </div>
        </>
      )}
      <QuickfiscoInputDoc
        docIsPresent={docIsPresent}
        error={error}
        errorLabel={lang.error}
        onChange={(doc) =>
          saveDoc(
            user,
            dataToSend,
            docObj,
            docType,
            doc,
            setStatus,
            setDocIsPresent,
            setError,
            dispatch
          )
        }
        deleteDoc={() =>
          deleteDoc(dataToSend, docObj, docType, setStatus, setError, dispatch)
        }
      />
      <DocumentsPreviewModal
        open={open}
        setOpen={setOpen}
        docId={docObj.objectId}
      />
    </div>
  );
}

function saveDoc(
  user: UserModel,
  dataToSend: UserRegistrationModel,
  docObj: DocModel,
  docType: string,
  doc: File | null,
  setStatus: SetState<PromiseStatuses>,
  setDocIsPresent: SetState<boolean>,
  setError: SetState<boolean>,
  dispatch: Function
): void {
  setError(false);

  if (doc === null) {
    return;
  }

  if (!validateFile(doc)) {
    setError(true);
    return;
  }

  const fileName =
    sanitizeString(
      `${docType === 'idDoc' ? 'idDoc' : 'taxDoc'}-${user.surname}-${
        user.name
      }-${user.id}`
    ) + '.';

  const userService = new UserService();

  setStatus('loading');

  userService
    .saveDoc(doc, fileName)
    .then((objectId) => {
      const newDocObj = {
        ...docObj,
        objectId,
      };

      userService
        .onboardingUpdateUser({
          ...dataToSend,
          [docType === 'idDoc' ? 'idDoc' : 'taxDoc']: newDocObj,
        })
        .then(() => {
          setStatus('successfully');
          dispatch(getMe());
        })
        .catch((err) => {
          console.error(err);
          setStatus('failed');
        });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
      setDocIsPresent(false);
    });
}

function deleteDoc(
  dataToSend: UserRegistrationModel,
  docObj: DocModel,
  docType: string,
  setStatus: SetState<PromiseStatuses>,
  setError: SetState<boolean>,
  dispatch: Function
): void {
  setError(false);
  if (docObj.objectId) {
    const newDocObj = {
      ...docObj,
      objectId: undefined,
    };

    const userService = new UserService();
    const objectService = new ObjectService();

    setStatus('loading');
    if (docObj.objectId) {
      objectService
        .del(docObj.objectId)
        .then(() => {
          userService
            .onboardingUpdateUser({
              ...dataToSend,
              [docType === 'idDoc' ? 'idDoc' : 'taxDoc']: newDocObj,
            })
            .then(() => {
              setStatus('successfully');
              dispatch(getMe());
            })
            .catch((err) => {
              console.error(err);
              setStatus('failed');
            });
        })
        .catch((err) => {
          console.error(err);
          setStatus('failed');
        });
    }
  }
}

function getDownloadDoc(
  setStatus: SetState<PromiseStatuses>,
  docObj: DocModel
): void {
  if (docObj.objectId) {
    const service = new ObjectService();
    setStatus('loading');

    if (docObj.objectId) {
      service
        .findById(docObj.objectId)
        .then((data) => {
          const objFile = data;

          if (objFile.id) {
            service
              .findFile(objFile.id)
              .then((data) => {
                setStatus('idle');
                if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
              })
              .catch((err) => {
                setStatus('failed');
                console.error(err);
              });
          }
        })
        .catch((err) => {
          setStatus('failed');
          console.error(err);
        });
    }
  }
}
