import { useNavigate } from 'react-router-dom';
import { MessageType } from '../../models/messagesModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { plainString } from '../../utils/string';
import { UserService } from '../../services/userService';
import { setProfileNotSavedModalIsOpen } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoUri.json';
import lang from './messagesTimetable.json';
import './messagesTimetable.css';

export function MessagesTimetable() {
  const messagesListState = useAppSelector((state) => state.messagesList);
  const messages = messagesListState.messages.content;
  const userState = useAppSelector((state) => state.user);
  const user = userState.user;
  const editUserRequest = userState.editUserRequest;

  const editingMe = UserService.editingMe(user, editUserRequest, user.status !== 4);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToMessage = async (messageId: string) => {
    navigate(uri.Messages + '/' + messageId);
  };

  if (messages.length === 0 || messagesListState.status === 'failed') {
    return (
      <div className={'messages-time-table-container'}>
        <div className={'row messages-time-table-title-container'}>
          <div className={'col-12'}>
            <span className={'messages-time-table-title'}>{lang.title}</span>
          </div>
        </div>
        <div className={'row mt-2'}>
          <div className={'col-12'}>
            <div
              className={
                'd-flex justify-content-center messages-time-table-no-message py-4'
              }
            >
              {messages.length === 0 && lang.noMessage}
              {messagesListState.status === 'failed' && lang.loadingError}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (messagesListState.status === 'loading') {
    return (
      <div className={'messages-time-table-container'}>
        <div className={'row messages-time-table-title-container'}>
          <div className={'col-12'}>
            <span className={'messages-time-table-title'}>{lang.title}</span>
          </div>
        </div>
        <div className={'row mt-2'}>
          <div className={'col-12 py-4'}>
            <div className={'d-flex justify-content-center'}>
              <QuickfiscoSpinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  let messagesList = messages.map((message, index) => {
    let messageType: string;
    switch (message.type) {
      case MessageType.FULLFILLMENTS:
        messageType = lang.fulfillments;
        break;
      case MessageType.HEY:
        messageType = lang.hey;
        break;
      case MessageType.NEWS:
        messageType = lang.news;
        break;
      case MessageType.WARNING:
        messageType = lang.warning;
        break;
      default:
        return <div>Tipo di messaggio non valido.</div>;
    }
    return (
      <div
        className={`row messages-time-table-row-container${
          message.unread ? '-unread' : ''
        }`}
        key={message.id}
        onClick={() => {
          if (editingMe) {
            dispatch(setProfileNotSavedModalIsOpen(true));
          } else {
            goToMessage(message.id);
          }
        }}
      >
        <div className={'col-12'}>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <span
                className={`messages-time-table-type messages-time-table-type${
                  message.unread ? '-unread' : ''
                }-${message.type.toLowerCase()}`}
              >
                {messageType}
              </span>
              <span
                className={`messages-time-table-title-msg${
                  message.unread === true ? '-unread' : ''
                }`}
              >
                {message.objectMessage}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div
              className={`col-12 pb-4 messages-time-table-text-msg${
                index !== messages.length - 1 ? '-bordered' : ''
              }`}
            >
              {message.textMessage && message.textMessage.length > 70
                ? `${plainString(message.textMessage).substring(0, 70)}...`
                : plainString(message.textMessage)}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={'messages-time-table-container'}>
      <div className={'row messages-time-table-title-container'}>
        <div className={'col-12'}>
          <span className={'messages-time-table-title'}>{lang.title}</span>
        </div>
      </div>
      <div className={'row messages-time-table-list'}>
        <div className={'col-12'}>{messagesList}</div>
      </div>
      <div className={'row mt-3'}>
        <div
          className={
            'col-12 messages-time-table-cta py-1 d-flex justify-content-center align-items-center'
          }
          onClick={() => {
            if (editingMe) {
              dispatch(setProfileNotSavedModalIsOpen(true));
            } else {
              navigate(uri.Messages);
            }
          }}
        >
          {lang.cta}
          <QuickfiscoIcon name={'message-read.svg'} />
        </div>
      </div>
    </div>
  );
}
