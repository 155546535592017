import { PaginationModel } from '../../models/paginationModel';
import { useAppSelector } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { MessagesListRow } from '../messagesListRow/messagesListRow';
import { EmptyList } from '../emptyList/emptyList';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { MessagesModel } from '../../models/messagesModel';
import lang from './messagesList.json';
import './messagesList.css';

export function MessagesList() {
  const messagesListState = useAppSelector((state) => state.messagesList);

  let status: PromiseStatuses;
  let messages: PaginationModel<MessagesModel>;
  status = messagesListState.status;
  messages = messagesListState.messages;

  if (status === 'loading') {
    return (
      <div className={'messagesList-empty'}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="messagesList-empty">
        <div className={'p-blue'}>{lang.loadingError}</div>
      </div>
    );
  }

  if (messages.content && messages.content.length <= 0) {
    return (
      <div className={'messagesList-empty'}>
        <EmptyList type={'messages'} />
      </div>
    );
  }

  const rows = messages.content?.map((message) => {
    return <MessagesListRow message={message} key={message.id} />;
  });

  return (
    <div className={'messagesList-container'}>
      <div className={'row no-gutters mb-3'}>
        <div className={'col messagesList-heading messagesList-type'}>{lang.line1}</div>
        <div className={'col messagesList-heading messagesList-message'}>{lang.line2}</div>
        <div className={'col messagesList-heading messagesList-attachment'}>{lang.line3}</div>
        <div className={'col messagesList-heading messagesList-date'}>{lang.line4}</div>
        <div className={'col-auto'}>
          <div style={{ width: '25px' }} />
        </div>
      </div>
      {rows}
    </div>
  );
}
