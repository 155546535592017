import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useAppSelector } from '../../redux/hooks';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { CustomerInvoiceService } from "../../services/customerInvoiceService";
import { ProformaInvoiceService } from '../../services/proformaInvoiceService';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { OccasionalJobInvoiceService } from '../../services/occasionalJobInvoiceService';
import { SetState } from '../../types/functions';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { InvoiceModel } from '../../models/invoiceModel';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from "../quickfiscoModal/quickfiscoModal";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import "./seePreviewPdfModal.css";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClick: () => void;
    invoiceSign: InvoiceSign;
    downloadPdfStatus: PromiseStatuses;
    occasionalJob?: InvoiceModel;
}

export function SeePreviewPdfModal(props: Props) {
    const { open } = props;
    const { setOpen } = props;
    const { onClick } = props;
    const { invoiceSign } = props;
    const { downloadPdfStatus } = props;
    const { occasionalJob } = props;

    const customerInvoiceState = useAppSelector(state => state.customerInvoice.invoice)
    const stsInvoiceState = useAppSelector(state => state.stsInvoice.invoice)
    const proformaInvoiceState = useAppSelector(state => state.proformaInvoice.invoice)
    const autoInvoiceState = useAppSelector(state => state.autoInvoice.invoice)

    const [filePreview, setFilePreview] = useState<Uint8Array>()
    const [name, setName] = useState<string>('')
    const [id, setId] = useState<string>('')

    useEffect(() => {
        if (invoiceSign === 'customer' && open && customerInvoiceState.id !== undefined) {
            getFileUrl(customerInvoiceState.id, setFilePreview, invoiceSign)
            setName(CustomerInvoiceService.getPdfName('customer', customerInvoiceState.type, customerInvoiceState.number, customerInvoiceState.customer?.companyName))
            setId(customerInvoiceState.id)
        } else if (invoiceSign === 'sts' && open && stsInvoiceState.id !== undefined) {
            getFileUrl(stsInvoiceState.id, setFilePreview, invoiceSign)
            setName(StsInvoiceService.getPdfName('sts', undefined, stsInvoiceState.number, stsInvoiceState.customer?.companyName))
            setId(stsInvoiceState.id)
        } else if (invoiceSign === 'proforma' && open && proformaInvoiceState.id !== undefined) {
            getFileUrl(proformaInvoiceState.id, setFilePreview, invoiceSign)
            setName(ProformaInvoiceService.getPdfName('proforma', proformaInvoiceState.type, proformaInvoiceState.number, proformaInvoiceState.customer?.companyName))
            setId(proformaInvoiceState.id)
        } else if (invoiceSign === 'auto' && open && autoInvoiceState.id !== undefined) {
            getFileUrl(autoInvoiceState.id, setFilePreview, invoiceSign)
            setName(AutoInvoiceService.getPdfName('auto', undefined, autoInvoiceState.number, autoInvoiceState.supplier?.companyName))
            setId(autoInvoiceState.id)
        } else if (invoiceSign === 'occasionalJob' && open && (occasionalJob && occasionalJob.id !== undefined)) {
            getFileUrl(occasionalJob.id, setFilePreview, invoiceSign)
            setName(AutoInvoiceService.getPdfName('occasionalJob', undefined, occasionalJob.number, occasionalJob.customer?.companyName))
            setId(occasionalJob.id)
        }
    }, [open, invoiceSign, customerInvoiceState.id, stsInvoiceState.id, proformaInvoiceState.id, autoInvoiceState.id])

    return (
        <div className={'invoice-pdf-modal-container'}>
            <QuickfiscoModal isOpen={open} hide={() => { setOpen(false); }} >
                <div className={'p-2'}>
                    <div className={'row'}>
                        <div className={'col-10 offset-1 text-center'}>
                            <div
                                className={
                                    'invoice-pdf-modal-title d-flex align-items-center justify-content-center'
                                }
                            >
                                <div>{name}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-10 offset-1 text-center border-gray'}>
                            <div className="invoice-pdf-modal-render-file d-flex justify-content-center align-items-center my-4">
                                {id ? (
                                    <Document
                                        file={filePreview}
                                        noData={
                                            <div className={'w-100 h-100 d-flex justify-content-center align-items-center'}>
                                                <QuickfiscoSpinner />
                                            </div>
                                        }
                                        loading={
                                            <div className={'w-100 h-100 d-flex justify-content-center align-items-center'}>
                                                <QuickfiscoSpinner />
                                            </div>
                                        }
                                        error="Anteprima non disponibile: il file non è in formato PDF"
                                    >
                                        <Page pageNumber={1} />
                                    </Document>
                                ) : (
                                    <div>Errore durante il caricamento del file</div>
                                )}
                            </div>
                        </div>
                        <div className={'row mt-5'}>
                            <div className={'col-4 offset-4 text-left'}>
                                {downloadPdfStatus === 'loading' ? (
                                    <div
                                        className={
                                        'w-100 d-flex justify-content-center align-items-center'
                                        }
                                    >
                                        <QuickfiscoSpinner />
                                    </div>
                                    ) : (
                                        <QuickfiscoButton
                                        id={'invoice-modal-download-pdf'}
                                        label="Scarica pdf"
                                        type={'primary'}
                                        onClick={() => onClick && onClick()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </QuickfiscoModal>
        </div>
    )
}

async function getFileUrl(invoiceId: string, setFilePreview: SetState<any | null>, invoiceSign: InvoiceSign): Promise<void> {
    if (invoiceSign === 'customer') {
        const service = new CustomerInvoiceService();
        return await service.getPdf(invoiceId).then(buff => setFilePreview({ data: new Uint8Array(buff) }));
    } else if (invoiceSign === 'auto') {
        const service = new AutoInvoiceService();
        return await service.getPdf(invoiceId).then(buff => setFilePreview({ data: new Uint8Array(buff) }));
    }
    else if (invoiceSign === 'proforma') {
        const service = new ProformaInvoiceService();
        return await service.getPdf(invoiceId).then(buff => setFilePreview({ data: new Uint8Array(buff) }));
    }
    else if (invoiceSign === 'sts') {
        const service = new StsInvoiceService();
        return await service.getPdf(invoiceId).then(buff => setFilePreview({ data: new Uint8Array(buff) }));
    }
    else if (invoiceSign === 'occasionalJob') {
        const service = new OccasionalJobInvoiceService();
        return await service.getPdf(invoiceId).then(buff => setFilePreview({ data: new Uint8Array(buff) }));
    }
}
